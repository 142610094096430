import httpClient from "../helpers/httpClient";

export const getCurrentSubscription = () => {
    return httpClient.get("/api/v3/users/subscription");
};

export const updateSubscriptionQuantity = (
    subId: string,
    quantity: number,
    teamId: string
) => {
    return httpClient.post(`/api/v3/users/subscription/${subId}/${teamId}`, {
        quantity,
    });
};

export const cancelSubscription = (subId: string) => {
    return httpClient.post(`/api/v3/users/subscription/${subId}/cancel`, {});
};
