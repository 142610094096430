import React from "react";
import NewRow from "./NewRow";
import PlaylistsRow from "./PlaylistsRow";
import jpGradientBGImage from "./../../../assets/images/jp-gradient.png";
import CategoryRow from "./CategoryRow";

import styles from "./index.module.scss";
import TeamAlbumRow from "./TeamAlbum";
import RowLoader from "../../core/component/RowLoader";

const Dashboard = () => {
    return (
        <div>
            <div
                style={{ backgroundImage: `url(${jpGradientBGImage})` }}
                className={`bg-contain lg:bg-contain 4xl:bg-cover  bg-top bg-no-repeat -mt-10 md:-mt-16 lg:-mt-24 ${styles.headerImage}`}
            >
                <div>
                    <div className={styles.spacer}></div>

                    <div className="md:py-4 lg:py-10 md:px-2 lg:px-20 space-y-6">
                        <TeamAlbumRow loading={<RowLoader />} />

                        <div className={styles.itemsRow}>
                            <PlaylistsRow loading={<RowLoader />} />
                        </div>
                        <div className={`relative ${styles.itemsRow}`}>
                            <NewRow loading={<RowLoader />} />
                        </div>
                        <div className={`relative ${styles.itemsRow} mt-8`}>
                            <CategoryRow loading={<RowLoader />} />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Dashboard;
