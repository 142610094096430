import React, { FormEvent, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import Spinner from "../../core/component/Spinner";
import countryCodes from "../../core/helpers/countryCodes";
import { registerRequest } from "../../core/services/auth.service";
const Register = () => {
    const [formData, setFormData] = useState({
        first_name: "",
        last_name: "",
        company: "",
        phone: "",
        email: "",
        email_confirmation: "",
        password: "",
        password_confirmation: "",
        billing_address_1: "",
        billing_city: "",
        billing_state: "",
        billing_country: "US",
        billing_zip: "",
        billing_conditions: "",
        terms_conditions: 1,
    });

    const history = useHistory();

    const onSubmit = (ev: FormEvent) => {
        setSubmitting(true);
        ev.preventDefault();

        registerRequest(formData)
            .then((resp) => {
                toast.success(
                    "Registration completed successfully. Please login to continue with your account."
                );

                history.push("/auth/");
            })
            .finally(() => {
                setSubmitting(false);
            });
    };

    const countries = countryCodes;

    const [submitting, setSubmitting] = useState(false);

    return (
        <div>
            <h2 className="mb-6 text-3xl font-extrabold text-white">
                Register your account
            </h2>
            <form action="#" className="space-y-6" onSubmit={onSubmit}>
                <div className="space-y-1">
                    <label className="block text-base font-thin text-gray-300">
                        First Name
                    </label>
                    <div className="mt-1">
                        <input
                            name="first_name"
                            type="text"
                            required
                            className="appearance-none block bg-transparent text-white w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-jp-blue focus:border-jp-blue sm:text-sm"
                            onChange={(ev) => {
                                setFormData((currentState) => {
                                    return {
                                        ...currentState,
                                        first_name: ev.target.value,
                                    };
                                });
                            }}
                        />
                    </div>
                </div>

                <div className="space-y-1">
                    <label className="block text-base font-thin text-gray-300">
                        Last Name
                    </label>
                    <div className="mt-1">
                        <input
                            name="last_name"
                            type="text"
                            required
                            className="appearance-none block bg-transparent text-white w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-jp-blue focus:border-jp-blue sm:text-sm"
                            onChange={(ev) => {
                                setFormData((currentState) => {
                                    return {
                                        ...currentState,
                                        last_name: ev.target.value,
                                    };
                                });
                            }}
                        />
                    </div>
                </div>

                <div className="space-y-1">
                    <label className="block text-base font-thin text-gray-300">
                        Company
                    </label>
                    <div className="mt-1">
                        <input
                            name="company"
                            type="text"
                            required
                            className="appearance-none block bg-transparent text-white w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-jp-blue focus:border-jp-blue sm:text-sm"
                            onChange={(ev) => {
                                setFormData((currentState) => {
                                    return {
                                        ...currentState,
                                        company: ev.target.value,
                                    };
                                });
                            }}
                        />
                    </div>
                </div>

                <div className="space-y-1">
                    <label className="block text-base font-thin text-gray-300">
                        Address 1
                    </label>
                    <div className="mt-1">
                        <input
                            name="billing_address_1"
                            type="text"
                            required
                            className="appearance-none block bg-transparent text-white w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-jp-blue focus:border-jp-blue sm:text-sm"
                            onChange={(ev) => {
                                setFormData((currentState) => {
                                    return {
                                        ...currentState,
                                        billing_address_1: ev.target.value,
                                    };
                                });
                            }}
                        />
                    </div>
                </div>

                <div className="space-y-1">
                    <label className="block text-base font-thin text-gray-300">
                        City
                    </label>
                    <div className="mt-1">
                        <input
                            name="billing_city"
                            type="text"
                            required
                            className="appearance-none block bg-transparent text-white w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-jp-blue focus:border-jp-blue sm:text-sm"
                            onChange={(ev) => {
                                setFormData((currentState) => {
                                    return {
                                        ...currentState,
                                        billing_city: ev.target.value,
                                    };
                                });
                            }}
                        />
                    </div>
                </div>

                <div className="space-y-1">
                    <label className="block text-base font-thin text-gray-300">
                        State
                    </label>
                    <div className="mt-1">
                        <input
                            name="billing_state"
                            type="text"
                            required
                            className="appearance-none block bg-transparent text-white w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-jp-blue focus:border-jp-blue sm:text-sm"
                            onChange={(ev) => {
                                setFormData((currentState) => {
                                    return {
                                        ...currentState,
                                        billing_state: ev.target.value,
                                    };
                                });
                            }}
                        />
                    </div>
                </div>

                <div className="space-y-1">
                    <label className="block text-base font-thin text-gray-300">
                        Country
                    </label>
                    <div className="mt-1">
                        <select
                            name="billing_country"
                            required
                            className="appearance-none block bg-transparent text-white w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-jp-blue focus:border-jp-blue sm:text-sm"
                            onChange={(ev) => {
                                setFormData((currentState) => {
                                    return {
                                        ...currentState,
                                        billing_country: ev.target.value,
                                    };
                                });
                            }}
                        >
                            {countries.map((country) => {
                                return (
                                    <option
                                        value={country.code}
                                        key={country.code}
                                        selected={
                                            "Australia" === country.name
                                        }
                                    >
                                        {country.name}
                                    </option>
                                );
                            })}
                        </select>
                    </div>
                </div>

                <div className="space-y-1">
                    <label className="block text-base font-thin text-gray-300">
                        Postal Code
                    </label>
                    <div className="mt-1">
                        <input
                            name="billing_zip"
                            type="text"
                            required
                            className="appearance-none block bg-transparent text-white w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-jp-blue focus:border-jp-blue sm:text-sm"
                            onChange={(ev) => {
                                setFormData((currentState) => {
                                    return {
                                        ...currentState,
                                        billing_zip: ev.target.value,
                                    };
                                });
                            }}
                        />
                    </div>
                </div>

                <div className="space-y-1">
                    <label className="block text-base font-thin text-gray-300">
                        Phone
                    </label>
                    <div className="mt-1">
                        <input
                            name="phone"
                            type="tel"
                            required
                            className="appearance-none block bg-transparent text-white w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-jp-blue focus:border-jp-blue sm:text-sm"
                            onChange={(ev) => {
                                setFormData((currentState) => {
                                    return {
                                        ...currentState,
                                        phone: ev.target.value,
                                    };
                                });
                            }}
                        />
                    </div>
                </div>

                <div className="space-y-1">
                    <label
                        htmlFor="email"
                        className="block text-base font-thin text-gray-300"
                    >
                        Email address
                    </label>
                    <div className="mt-1">
                        <input
                            id="email"
                            name="email"
                            type="email"
                            autoComplete="email"
                            required
                            className="appearance-none block bg-transparent text-white w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-jp-blue focus:border-jp-blue sm:text-sm"
                            onChange={(ev) => {
                                setFormData((currentState) => {
                                    return {
                                        ...currentState,
                                        email: ev.target.value,
                                    };
                                });
                            }}
                        />
                    </div>
                </div>

                <div className="space-y-1">
                    <label className="block text-base font-thin text-gray-300">
                        Confirm email address
                    </label>
                    <div className="mt-1">
                        <input
                            name="email_confirmation"
                            type="email"
                            required
                            className="appearance-none block bg-transparent text-white w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-jp-blue focus:border-jp-blue sm:text-sm"
                            onChange={(ev) => {
                                setFormData((currentState) => {
                                    return {
                                        ...currentState,
                                        email_confirmation: ev.target.value,
                                    };
                                });
                            }}
                        />
                    </div>
                </div>

                <div className="space-y-1">
                    <label
                        htmlFor="password"
                        className="block text-base font-thin text-gray-300"
                    >
                        Password
                    </label>
                    <div className="mt-1">
                        <input
                            id="password"
                            name="password"
                            type="password"
                            autoComplete="current-password"
                            required
                            className="appearance-none block bg-transparent text-white w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-jp-blue focus:border-jp-blue sm:text-sm"
                            onChange={(ev) => {
                                setFormData((currentState) => {
                                    return {
                                        ...currentState,
                                        password: ev.target.value,
                                    };
                                });
                            }}
                        />
                    </div>
                </div>

                <div className="space-y-1">
                    <label className="block text-base font-thin text-gray-300">
                        Confirm Password
                    </label>
                    <div className="mt-1">
                        <input
                            name="password_confirmation"
                            type="password"
                            autoComplete="current-password"
                            required
                            className="appearance-none block bg-transparent text-white w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-jp-blue focus:border-jp-blue sm:text-sm"
                            onChange={(ev) => {
                                setFormData((currentState) => {
                                    return {
                                        ...currentState,
                                        password_confirmation: ev.target.value,
                                    };
                                });
                            }}
                        />
                    </div>
                </div>

                <div className={"text-white"}>
                    By signing up you agree all the terms and conditions.
                </div>

                <button
                    type="submit"
                    className="w-full flex justify-center py-2  rounded-md text-lg font-light bg-white items-center gap-4"
                    disabled={submitting}
                >
                    Register {submitting && <Spinner />}
                </button>
            </form>
            <div className="mt-8">
                <p className="inline text-gray-300 text-base font-light mr-1">
                    Already have an account?{" "}
                </p>
                <Link to={"/auth"} className="text-jp-blue">
                    Sign in
                </Link>
            </div>
        </div>
    );
};

export default Register;
