import React, { useContext, useState } from "react";
import Swal from "sweetalert2";
import ProgressBar from "../../core/component/ProgressBar";
import { PlusIcon } from "@heroicons/react/solid";
import { toast } from "react-toastify";
import {
    deleteTeamAlbumVideo,
    saveTeamAlbumTracks,
} from "../../core/services/album.service";
import { AuthContext } from "../../core/context/auth";

const TracksManagement = ({ tracks = [], setTracks, albumId = "" }: any) => {
    const { authState } = useContext(AuthContext);
    const [file, setFile] = useState<any>(null);

    const uploadFile = async (ev: any, track: any) => {
        const file = ev.target.files[0];
        setFile(file);
        if (file.type !== "video/mp4") {
            toast.error("Videos must be in mp4 format.");
            return;
        }

        saveTeamAlbumTracks(
            authState.user.relationships.team.id,
            albumId,
            {
                ...track,
                video: file,
            },
            (ev: any) => {
                setTracks((currentState: any) => {
                    return [
                        ...currentState.map((currentStateTrack: any) => {
                            if (track.id === currentStateTrack.id) {
                                currentStateTrack["progress"] =
                                    (ev.loaded / ev.total) * 100;
                            }
                            return currentStateTrack;
                        }),
                    ];
                });
            }
        ).then((resp) => {
            toast.success("Track uploaded successfully.");
            setTracks((currentState: any) => {
                return [
                    ...currentState.map((currentStateTrack: any) => {
                        if (track.id === currentStateTrack.id) {
                            currentStateTrack["id"] = resp.data.id;
                        }
                        return currentStateTrack;
                    }),
                ];
            });
        });
    };

    return albumId ? (
        <div>
            <div className="w-2/4 space-y-6">
                <div className="text-2xl">Add tracks to albums</div>

                {tracks.map((track: any) => {
                    return (
                        <div
                            className="bg-jp-black-700 py-6 px-4 rounded space-y-10"
                            key={track.id}
                        >
                            <div className={"border p-2 rounded relative"}>
                                <div
                                    className={
                                        "absolute -top-2 bg-jp-black-700 text-xs px-1 text-gray-400"
                                    }
                                >
                                    Title
                                </div>
                                <input
                                    type="text"
                                    className={
                                        "bg-transparent w-full outline-none"
                                    }
                                    defaultValue={track.title}
                                    onChange={(ev) => {
                                        setTracks((currentState: any) => {
                                            const localAlbumIndex =
                                                currentState.findIndex(
                                                    (x: any) =>
                                                        x.id === track.id
                                                );
                                            currentState[
                                                localAlbumIndex
                                            ].title = ev.target.value;
                                            return [...currentState];
                                        });
                                    }}
                                    placeholder="Title name"
                                />
                            </div>
                            <div className={"border p-2 rounded relative"}>
                                <div
                                    className={
                                        "absolute -top-2 bg-jp-black-700 text-xs px-1 text-gray-400"
                                    }
                                >
                                    Description
                                </div>
                                <input
                                    type="text"
                                    className={
                                        "bg-transparent w-full outline-none"
                                    }
                                    defaultValue={track.description}
                                    onChange={(ev) => {
                                        setTracks((currentState: any) => {
                                            const localAlbumIndex =
                                                currentState.findIndex(
                                                    (x: any) =>
                                                        x.id === track.id
                                                );
                                            currentState[
                                                localAlbumIndex
                                            ].description = ev.target.value;
                                            return [...currentState];
                                        });
                                    }}
                                    placeholder="Description"
                                />
                            </div>

                            <div className="">
                                {track.isEdit ? (
                                    <div className="flex  items-center justify-between">
                                        <p className="text-jp-blue">
                                            {track?.name}
                                        </p>
                                        <button
                                            className="text-red-500"
                                            onClick={() => {
                                                Swal.fire({
                                                    text: "Are you you want to delete track?",
                                                    showCancelButton: true,
                                                    confirmButtonText: "Delete",
                                                    confirmButtonColor:
                                                        "rgb(239, 68, 68)",
                                                }).then((result) => {
                                                    if (result.isConfirmed) {
                                                        deleteTeamAlbumVideo(
                                                            track.id
                                                        ).then(() => {
                                                            toast.success(
                                                                "Track deleted successfully."
                                                            );
                                                            setTracks(
                                                                (
                                                                    currentState: any
                                                                ) => {
                                                                    return [
                                                                        ...currentState.filter(
                                                                            (
                                                                                x: any
                                                                            ) =>
                                                                                x.id !==
                                                                                track.id
                                                                        ),
                                                                    ];
                                                                }
                                                            );
                                                        });
                                                    }
                                                });
                                            }}
                                        >
                                            Remove
                                        </button>
                                    </div>
                                ) : (
                                    <div className="flex flex-col items-center justify-center">
                                        <label className="text-gray-400 rounded-full py-2 px-8  cursor-pointer bg-jp-black">
                                            Select video file
                                            <input
                                                type="file"
                                                className="w-0"
                                                accept="videos/*"
                                                disabled={
                                                    track.progress &&
                                                    track.progress !== "100"
                                                }
                                                onChange={(ev: any) => {
                                                    if (!track.title) {
                                                        toast.error(
                                                            "Please enter the title and description for the track."
                                                        );
                                                        return;
                                                    }
                                                    uploadFile(ev, track);
                                                }}
                                            />
                                        </label>
                                        <div className="text-xs text-gray-400 italic mt-2">
                                            Videos must be in mp4 format
                                        </div>
                                    </div>
                                )}
                                {track.progress && track.progress < 100 ? (
                                    <div className="mt-2">
                                        <ProgressBar
                                            percent={track.progress}
                                            title={""}
                                            background={"#29A8E8"}
                                        />
                                    </div>
                                ) : (
                                    track.progress === 100 && (
                                        <p className="text-jp-blue mt-12">
                                            {file?.name}
                                        </p>
                                    )
                                )}
                            </div>
                        </div>
                    );
                })}

                <div
                    className="grid place-content-center p-5 border border-dotted rounded cursor-pointer"
                    onClick={() => {
                        setTimeout(() => {
                            window.scrollTo(0, 99999);
                        }, 200);

                        setTracks((currentState: any) => {
                            return [
                                ...currentState,
                                {
                                    id: new Date().valueOf(),
                                    name: "",
                                    description: "",
                                    file_name: "",
                                    is_local: true,
                                },
                            ];
                        });
                    }}
                >
                    <PlusIcon className="w-6" />
                </div>
            </div>
        </div>
    ) : null;
};

export default TracksManagement;
