import httpClient from "../helpers/httpClient";

export const createQuestion = (
    albumid: string,
    params: { title: string; type: string }
) => {
    return httpClient.post(`/api/v3/albums/${albumid}/questions`, params);
};

export const updateQuestion = (
    albumId: string,
    questionId: string,
    params: { title: string; type: string }
) => {
    return httpClient.patch(
        `/api/v3/albums/${albumId}/questions/${questionId}`,
        params
    );
};

export const saveAnswers = (
    albumid: string,
    questionId: string,
    params: any
) => {
    return httpClient.post(
        `/api/v3/albums/${albumid}/questions/${questionId}/answers`,
        { answers: params }
    );
};

export const deleteQuestion = (albumid: string, questionId: string) => {
    return httpClient.delete(
        `/api/v3/albums/${albumid}/questions/${questionId}`
    );
};

export const submitAnswer = (questionId: string, answerId: string) => {
    return httpClient.post(`/api/v3/questions/${questionId}/answer`, {
        answer: answerId,
    });
};

export const getQuizResults = (albumid: string) => {
    return httpClient.get(`/api/v3/albums/${albumid}/quiz`);
};

export const reorder = (list: any[], startIndex: number, endIndex: number) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
};
