import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import BackBar from "../../core/component/BackBar";
import RowLoader from "../../core/component/RowLoader";
import { getPlaylists } from "../../core/services/playlist.service";
import jpGradientBGImage from "./../../../assets/images/jp-gradient.png";

import styles from "./playlists.module.scss";

const AllPlaylists = () => {
    const [playlists, setPlaylists] = useState([]);
    useEffect(() => {
        getPlaylists({ size: 0 })
            .then((resp) => {
                setPlaylists(resp.data);
            })
            .catch((err) => {
                console.log("🚀 ~ err", err);
            });
    }, []);
    return (
        <div
            style={{ backgroundImage: `url(${jpGradientBGImage})` }}
            className={`bg-contain lg:bg-contain 4xl:bg-cover  bg-top bg-no-repeat -mt-10 md:-mt-16 lg:-mt-24 ${styles.headerImage}`}
        >
            <div
                className={`container p-4 pt-16 lg:p-16 flex items-center ${styles.spacer}`}
            >
                <div className="w-2/3 md:w-1/4 mt-20 md:mt-0">
                    <BackBar />
                    <div className="uppercase text-xl md:text-2xl lg:text-4xl text-white">
                        Playlists
                    </div>
                    <div className="text-gray-400 text-sm md:text-base">
                        Learn about detailed topics using our curated Playlists.
                    </div>
                </div>
            </div>
            <div className="container p-4 pt-4 md:pt-16 lg:p-16 space-y-2 md:space-y-6 w-full min-h-screen">
                {playlists.length ? (
                    <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-6 md:gap-8 lg:gap-10 ">
                        {playlists.map((playlist: any) => {
                            return (
                                <div key={playlist.id}>
                                    <Link to={"/playlists/" + playlist.id}>
                                        <div className="w-full aspect-w-16 aspect-h-9">
                                            <div
                                                className="text-white uppercase tracking-wide bg-no-repeat bg-cover bg-bottom flex flex-row items-end pb-6 md:pb-8 lg:pb-10 px-2 lg:px-5 cursor-pointer rounded"
                                                style={{
                                                    backgroundImage: `url(${playlist.attributes.image}`,
                                                }}
                                            >
                                                <div className="text-white uppercase tracking-wide w-1/2 text-xs md:text-base">
                                                    {playlist.attributes.title}
                                                </div>
                                            </div>
                                        </div>
                                    </Link>
                                </div>
                            );
                        })}
                    </div>
                ) : (
                    <RowLoader />
                )}
            </div>
        </div>
    );
};

export default AllPlaylists;
