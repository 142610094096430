import httpClient from "../helpers/httpClient";

export const saveAndEndSession = (payload: any) => {
    return httpClient.post("/api/v3/sessions", payload);
};

export const getSessionsByUser = (user_id: number | string) => {
    return httpClient.get("/api/v3/sessions", { params: { user_id } });
};

export const getKPIStats = (from: string, to: string) => {
    return httpClient.get(`/api/v3/sessions/kpi/${from}/${to}`);
};
