import React, { useContext, useEffect, useState } from "react";
import { Link, Redirect, Route } from "react-router-dom";
import RightSidebar from "../component/RightSidebar";
import { AuthContext } from "../context/auth";
import whiteVectorImage from "./../../../assets/images/white-vector.svg";

import TopMenu from "./topMenu";

const ProtectedRoute = ({ component, header = true, ...restOfProps }: any) => {
    const { authState } = useContext(AuthContext);

    const [isReady, setIsReady] = useState(false);

    const [open, setOpen] = useState(false);

    useEffect(() => {
        if (!authState.loading) {
            setIsReady(true);
        }
    }, [authState]);

    return (
        <div className="">
            {header && (
                <div className="px-6 lg:px-20 flex justify-between pt-0 lg:pt-8 items-center">
                    <Link to="/">
                        <img
                            src={whiteVectorImage}
                            alt="Logo"
                            className="pt-2 lg:pt-0 w-10 md:w-12 lg:w-14 items-center"
                        />
                    </Link>
                    <TopMenu setOpen={setOpen} />
                </div>
            )}
            <RightSidebar
                openState={open}
                onClose={() => {
                    setOpen(false);
                }}
            />

            {!isReady ? (
                <div className="text-white text-center py-6">Loading...</div>
            ) : (
                <>
                    {authState.token && authState.user ? (
                        <Route {...restOfProps} component={component} />
                    ) : (
                        <Redirect to={{ pathname: "/auth" }} />
                    )}
                </>
            )}
        </div>
    );
};

export default ProtectedRoute;
