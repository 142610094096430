import React, { useEffect, useRef, useState } from "react";
import {
    MinusCircleIcon,
    PauseIcon,
    PlusCircleIcon,
} from "@heroicons/react/solid";

import { PlayIcon } from "@heroicons/react/outline";
import styles from "./index.module.scss";
import Countdown from "react-countdown";
import { getLoggedInUser } from "../../core/services/auth.service";
import { saveAndEndSession } from "../../core/services/callTimer.service";
import moment from "moment";
import { getCurrentSubscription } from "../../core/services/subscription.service";

const CallTimerWidget = () => {
    const countDownRef = useRef<any>();

    const [sessionData, setSessionData] = useState({
        calls: 0,
        connects: 0,
        bap: 0,
        map: 0,
        lap: 0,
        duration: 0,
        user_id: 1,
        team_id: null,
    });

    const [startTime, setStartTime] = useState(Date.now() + 1000 * 60 * 45);

    const [isPlaying, setIsPlaying] = useState(false);

    const renderer = ({ hours, minutes, seconds, completed }: any) => {
        // Render a countdown
        return (
            <span>
                {minutes}:{seconds || "00"}
            </span>
        );
    };

    useEffect(() => {
        getCurrentSubscription().then((resp) => {
            if (!resp.data) {
                window.close();
            }
        });

        getLoggedInUser()
            .then((resp) => {
                setSessionData((currenState: any) => {
                    return {
                        ...currenState,
                        user_id: resp.data.data.attributes.id,
                    };
                });
            })
            .catch((err) => {
                alert("Failed to complete the request.");
            });
    }, []);

    return (
        <div className="container text-white  flex justify-center">
            <div className={"w-full border border-gray-700"}>
                <div className="flex justify-between bg-black py-10 px-20 items-center">
                    <div className={"text-3xl"}>
                        <Countdown
                            date={startTime}
                            renderer={renderer}
                            autoStart={false}
                            ref={countDownRef}
                        />
                    </div>
                    <div className={"flex justify-end"}>
                        <button
                            className="w-1/4"
                            onClick={() => {
                                if (countDownRef.current.isPaused()) {
                                    countDownRef.current.api.start();
                                    setIsPlaying(true);
                                } else {
                                    if (!countDownRef.current.isStarted()) {
                                        setStartTime(
                                            Date.now() + 1000 * 60 * 45
                                        );
                                        countDownRef.current.api.start();
                                        setIsPlaying(true);
                                    } else {
                                        countDownRef.current.api.pause();
                                        setIsPlaying(false);
                                    }
                                }
                            }}
                        >
                            {isPlaying ? (
                                <PauseIcon
                                    className={`w-full ${styles.icons}`}
                                />
                            ) : (
                                <PlayIcon
                                    className={`w-full ${styles.icons}`}
                                />
                            )}
                        </button>
                    </div>
                </div>

                <div className={styles["grid-wrapper"]}>
                    <div className={styles["header-col-item"]}>KPI</div>
                    <div className={styles["header-col-item"]}>Actions</div>
                    <div className={styles["header-col-item"]}>Count</div>

                    <div>Calls</div>
                    <div className={"flex justify-center gap-4"}>
                        <MinusCircleIcon
                            className={`w-10 ${styles.icons}`}
                            onClick={() => {
                                setSessionData((currentState) => {
                                    return {
                                        ...currentState,
                                        calls: currentState.calls - 1,
                                    };
                                });
                            }}
                        />
                        <PlusCircleIcon
                            className={`w-10 ${styles.icons}`}
                            onClick={() => {
                                setSessionData((currentState) => {
                                    return {
                                        ...currentState,
                                        calls: currentState.calls + 1,
                                    };
                                });
                            }}
                        />
                    </div>
                    <div>{sessionData.calls}</div>

                    <div>Connects</div>
                    <div className={"flex justify-center gap-4"}>
                        <MinusCircleIcon
                            className={`w-10 ${styles.icons}`}
                            onClick={() => {
                                setSessionData((currentState) => {
                                    return {
                                        ...currentState,
                                        connects: currentState.connects - 1,
                                    };
                                });
                            }}
                        />
                        <PlusCircleIcon
                            className={`w-10 ${styles.icons}`}
                            onClick={() => {
                                setSessionData((currentState) => {
                                    return {
                                        ...currentState,
                                        connects: currentState.connects + 1,
                                    };
                                });
                            }}
                        />
                    </div>
                    <div>{sessionData.connects}</div>

                    <div className={styles["header-col-item"]}>
                        Appointments
                    </div>
                    <div className={styles["header-col-item"]}>&nbsp;</div>
                    <div className={styles["header-col-item"]}>&nbsp;</div>

                    <div>BAP</div>
                    <div className={"flex justify-center gap-4"}>
                        <MinusCircleIcon
                            className={`w-10 ${styles.icons}`}
                            onClick={() => {
                                setSessionData((currentState) => {
                                    return {
                                        ...currentState,
                                        bap: currentState.bap - 1,
                                    };
                                });
                            }}
                        />
                        <PlusCircleIcon
                            className={`w-10 ${styles.icons}`}
                            onClick={() => {
                                setSessionData((currentState) => {
                                    return {
                                        ...currentState,
                                        bap: currentState.bap + 1,
                                    };
                                });
                            }}
                        />
                    </div>
                    <div>{sessionData.bap}</div>

                    <div>MAP</div>
                    <div className={"flex justify-center gap-4"}>
                        <MinusCircleIcon
                            className={`w-10 ${styles.icons}`}
                            onClick={() => {
                                setSessionData((currentState) => {
                                    return {
                                        ...currentState,
                                        map: currentState.map - 1,
                                    };
                                });
                            }}
                        />
                        <PlusCircleIcon
                            className={`w-10 ${styles.icons}`}
                            onClick={() => {
                                setSessionData((currentState) => {
                                    return {
                                        ...currentState,
                                        map: currentState.map + 1,
                                    };
                                });
                            }}
                        />
                    </div>
                    <div>{sessionData.map}</div>

                    <div>LAP</div>
                    <div className={"flex justify-center gap-4"}>
                        <MinusCircleIcon
                            className={`w-10 ${styles.icons}`}
                            onClick={() => {
                                setSessionData((currentState) => {
                                    return {
                                        ...currentState,
                                        lap: currentState.lap - 1,
                                    };
                                });
                            }}
                        />
                        <PlusCircleIcon
                            className={`w-10 ${styles.icons}`}
                            onClick={() => {
                                setSessionData((currentState) => {
                                    return {
                                        ...currentState,
                                        lap: currentState.lap + 1,
                                    };
                                });
                            }}
                        />
                    </div>
                    <div>{sessionData.lap}</div>
                </div>
                <div>
                    <button
                        className={
                            "w-full py-4 uppercase bg-jp-blue hover:opacity-90 cursor-pointer transition"
                        }
                        onClick={() => {
                            const initialTimeStamp =
                                countDownRef.current.initialTimestamp;
                            const elapsedDuration = moment(new Date()).diff(
                                initialTimeStamp,
                                "seconds"
                            );

                            saveAndEndSession({
                                ...sessionData,
                                duration: elapsedDuration,
                            })
                                .then((resp) => {
                                    setTimeout(() => {
                                        window.close();
                                    }, 500);
                                })
                                .catch((err) => {
                                    console.log("🚀 ~ err", err);
                                    alert("Failed to save data.");
                                });
                        }}
                    >
                        Save and End Session
                    </button>
                </div>
            </div>
        </div>
    );
};

export default CallTimerWidget;
