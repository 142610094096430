import React from "react";
import booleanIcon from "./../../../assets/images/boolean.svg";
import multipleChoiceIcon from "./../../../assets/images/multiple-choice.svg";
import sortIcon from "./../../../assets/images/sort.svg";

const QuestionTypeIcon = ({ type }: any) => {
    return (
        <div>
            {type === "boolean" && <img src={booleanIcon} alt="boolean icon" />}
            {type === "multiple" && (
                <img src={multipleChoiceIcon} alt="mcq icon" />
            )}
            {type === "sort" && <img src={sortIcon} alt="sort icon" />}
        </div>
    );
};

export default QuestionTypeIcon;
