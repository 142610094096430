import httpClient from "../helpers/httpClient";

export const getPlaylists = (params: any) => {
    return httpClient.get("/api/v3/playlists", {
        params,
    });
};

export const getPlaylistById = (id: string) => {
    return httpClient.get("/api/v3/playlists/" + id);
};
