import React, { useEffect, useState } from "react";
import {
    ArrowCircleRightIcon,
    ChevronLeftIcon,
    ChevronRightIcon,
} from "@heroicons/react/solid";
import { Link } from "react-router-dom";
import { Swiper, SwiperSlide } from "swiper/react";
import { getCategories } from "../../core/services/category.service";
import styles from "./index.module.scss";
import placeholderImage from "./../../../assets/images/placeholder.jpg";

const CategoryRow = ({ loading }: any) => {
    const [categories, setCategories] = useState([]);

    useEffect(() => {

        getCategories({ size: 50, page: 1, source: "website" })
            .then((resp) => {
                setCategories(resp.data);
            })
            .catch((err) => {
                console.log("🚀 ~ err", err);
            });
    }, []);

    return (
        <div className="space-y-4 relative group">
            {categories.length ? (
                <>
                    <div className="flex justify-between -mb-8 lg:-mb-20 ">
                        <div className="text-gray-500 pl-3 uppercase flex-1">
                            Categories
                        </div>
                        <div className="text-white relative z-10  group-hover:opacity-100 transition opacity-100 md:opacity-0">
                            <Link
                                to="/categories"
                                className="flex items-center gap-2"
                            >
                                <span>View All</span>
                                <ArrowCircleRightIcon className="w-4" />
                            </Link>
                        </div>

                        {/* //controls */}
                        <div className="category-button-prev cursor-pointer hover:bg-gray-600 opacity-0 transition group-hover:opacity-100 hover:bg-opacity-40 absolute left-0 top-2/4 -mt-10 z-10">
                            <ChevronLeftIcon className="w-16 text-gray-500 hover:text-white" />
                        </div>

                        <div className="category-button-next cursor-pointer hover:bg-gray-600 opacity-0 transition group-hover:opacity-100 hover:bg-opacity-40 absolute right-0 top-2/4 -mt-10 z-10">
                            <ChevronRightIcon className="w-16 text-gray-500 hover:text-white" />
                        </div>
                    </div>
                    <Swiper
                        navigation={{
                            nextEl: ".category-button-next",
                            prevEl: ".category-button-prev",
                        }}
                        slidesPerView="auto"
                        allowTouchMove={false}
                    >
                        {categories.map((category: any, index: number) => {
                            return (
                                <SwiperSlide
                                    key={category.id}
                                    className={`my-8 lg:my-20 mx-3 hover:scale-125 transform-gpu transition rounded  hadow-2xl ${
                                        styles.zoomer
                                    } ${styles.itemInRow} ${
                                        index === 0 && " origin-left"
                                    } ${
                                        index === categories.length - 1 &&
                                        " origin-right"
                                    }`}
                                >
                                    <Link to={`/categories/${category.id}`}>
                                        <div
                                            style={{
                                                backgroundImage: `url(${
                                                    category.attributes
                                                        .mobile_image ||
                                                    placeholderImage
                                                }`,
                                            }}
                                            className="h-full w-full bg-no-repeat bg-cover bg-bottom flex flex-row items-center px-10 cursor-pointer rounded"
                                        ></div>
                                        <div className="text-white p-2 h-9 overflow-hidden">
                                            <div className="flex items-center gap-2">
                                                <div className="text-sm truncate">
                                                    {category.attributes.title}
                                                </div>
                                            </div>
                                        </div>
                                    </Link>
                                </SwiperSlide>
                            );
                        })}
                    </Swiper>
                </>
            ) : (
                loading
            )}
        </div>
    );
};

export default CategoryRow;
