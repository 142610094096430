import React, { useEffect, useState } from "react";
import pdfBG from "./../../../assets/images/pdf.svg";
import docBG from "./../../../assets/images/doc.svg";
import xlsBG from "./../../../assets/images/xls.svg";
import webBG from "./../../../assets/images/web.svg";
const ResourceType = (url: any) => {
    const [bgImage, setBgImage] = useState("");

    useEffect(() => {
        const localUrl = url.url.split(".");
        const extension = localUrl[localUrl.length - 1];

        if (extension === "pdf") {
            setBgImage(pdfBG);
        } else if (extension === "doc" || extension === "docx") {
            setBgImage(docBG);
        } else if (extension === "xls" || extension === "xlsx") {
            setBgImage(xlsBG);
        } else {
            setBgImage(webBG);
        }
    }, [url]);

    return (
        <div
            style={{
                backgroundImage: `url(${bgImage})`
            }}
            className="w-full bg-no-repeat bg-center bg-cover aspect-w-16 aspect-h-9"
        ></div>
    );
};

export default ResourceType;
