import React, { useEffect, useState } from "react";
import { PencilIcon, TrashIcon } from "@heroicons/react/solid";
import { reverse } from "lodash";
import { toast } from "react-toastify";
import { deleteQuestion } from "../../core/services/quiz.service";
import QuestionTypeIcon from "./QuestionTypeIcon";

const QuestionList = ({
    album,
    setRefreshAlbumTrigger,
    setEditQuestion,
}: any) => {
    const [questions, setQuestions] = useState([]);

    useEffect(() => {
        if (album) {
            setQuestions(() => reverse(album.relationships.quiz));
        }
    }, [album]);
    return (
        <div>
            <div className="text-lg mb-4">Questions</div>
            <div>
                {!questions.length && <div>No questions available.</div>}
                {questions.map((question: any) => {
                    return (
                        <div
                            key={question.id}
                            className="flex items-center mb-4 justify-between"
                        >
                            <div className="flex items-center gap-2">
                                <div>
                                    <QuestionTypeIcon
                                        type={question.attributes.type}
                                    />
                                </div>
                                <div>{question.attributes.title}</div>
                            </div>

                            <div className="flex gap-2">
                                <button
                                    className="text-jp-blue"
                                    onClick={(ev) => {
                                        setEditQuestion(question);
                                    }}
                                >
                                    <PencilIcon className="w-6" />
                                </button>
                                <button
                                    className="text-red-500"
                                    onClick={() => {
                                        deleteQuestion(
                                            album.id,
                                            question.id
                                        ).then((resp) => {
                                            setRefreshAlbumTrigger(
                                                (currentState: number) => {
                                                    return currentState + 1;
                                                }
                                            );
                                            toast.success(
                                                "Question deleted successfully."
                                            );
                                        });
                                    }}
                                >
                                    <TrashIcon className="w-6" />
                                </button>
                            </div>
                        </div>
                    );
                })}
            </div>
        </div>
    );
};

export default QuestionList;
