import React, { useContext, useEffect } from "react";
import { Route, Switch, useHistory, useRouteMatch } from "react-router-dom";
import Login from "./Login";
import Register from "./Register";
import jpLogoImage from "./../../../assets/images/JP_logo.svg";
import { AuthContext } from "../../core/context/auth";

import styles from "./index.module.scss";
import ResetPassword from "./ResetPassword";
import CreateNewPassword from "./CreateNewPassword";

const Auth = () => {
    const { path } = useRouteMatch();

    const history = useHistory();

    const { authState } = useContext(AuthContext);

    useEffect(() => {
        if (authState.token && authState.user) {
            history.push("/");
        }
    }, [authState]);

    return (
        <>
            {!authState.loading && (
                <div
                    className={`min-h-screen flex ${styles.background}`}
                >
                    <div className="flex-1 flex flex-col justify-center py-12 px-4 sm:px-6 lg:flex-none lg:px-20 xl:px-24">
                        <div className="mx-auto w-full max-w-sm lg:w-96">
                            <img
                                className="h-12 w-auto"
                                src={jpLogoImage}
                                alt="Workflow"
                            />

                            <div className="mt-6">
                                <Switch>
                                    <Route
                                        exact
                                        path={`${path}/register`}
                                        component={Register}
                                    />
                                    <Route
                                        exact
                                        path={`${path}/password`}
                                        component={ResetPassword}
                                    />
                                    <Route
                                        exact
                                        path={`${path}/password/reset`}
                                        component={CreateNewPassword}
                                    />
                                    <Route path={`${path}`} component={Login} />
                                </Switch>
                            </div>
                        </div>
                    </div>
                    {/* <div className="hidden lg:block relative w-0 flex-1">
                        <img
                            className="absolute inset-0 h-full w-full object-cover"
                            src="https://joshphegan.com.au/img/membership_home-min.jpg"
                            alt=""
                        />
                    </div> */}
                </div>
            )}
        </>
    );
};

export default Auth;
