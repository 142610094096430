import httpClient from "../helpers/httpClient";

export type DurationFilter = "today" | "week" | "month" | "quarter" | "year";

export const getUserStats = (userId: string, filter: DurationFilter) => {
    console.log("🚀 ~ file: analytic.service.tsx ~ getUserStats");

    return httpClient.get(`/api/v3/stats/user/${userId}`, {
        params: {
            filter,
        },
    });
};

export const getUserRecentAlbums = (
    userId: string,
    size: number,
    filter: DurationFilter,
    page = 1
) => {
    console.log("🚀 ~ file: analytic.service.tsx ~ getUserRecentAlbums");

    return httpClient.get(`/api/v3/users/${userId}/recent-albums`, {
        params: { size, page, filter },
    });
};

export const getUserRecentTeamAlbums = (
    userId: string,
    size: number,
    filter: DurationFilter
) => {
    console.log("🚀 ~ file: analytic.service.tsx ~ getUserRecentTeamAlbums");

    return httpClient.get(`/api/v3/users/${userId}/recent-team-albums`, {
        params: { size, filter },
    });
};

export const getTeamAlbums = (
    teamId: string,
    size: number,
    filter: DurationFilter,
    page = 1
) => {
    console.log("🚀 ~ file: analytic.service.tsx ~ getTeamAlbums");

    return httpClient.get(`/api/v3/stats/team/${teamId}/albums`, {
        params: { size, filter, page },
    });
};

export const getTeamGlobalAlbums = (
    teamId: string,
    size: number,
    filter: DurationFilter,
    page = 1
) => {
    console.log("🚀 ~ file: analytic.service.tsx ~ getTeamGlobalAlbums");

    return httpClient.get(`/api/v3/stats/team/${teamId}/global-albums`, {
        params: { size, page, filter },
    });
};

export const getMembersStats = (
    size: number,
    page: number,
    filter: DurationFilter,
    search: string
) => {
    console.log("🚀 ~ file: analytic.service.tsx ~ getMembersStats");

    return httpClient.get(`/api/v3/stats/all/members`, {
        params: { size, page, filter, search },
    });
};

export const getTeamMembersStats = (
    teamId: string,
    size: number,
    page: number,
    filter: DurationFilter,
    search: string
) => {
    console.log("🚀 ~ file: analytic.service.tsx ~ getTeamMembersStats");

    return httpClient.get(`/api/v3/stats/team/${teamId}/members`, {
        params: { size, page, filter, search },
    });
};

export const getTeamsStats = (
    size: number,
    page: number,
    filter: DurationFilter,
    search: string
) => {
    console.log("🚀 ~ file: analytic.service.tsx ~ getTeamsStats");

    return httpClient.get(`/api/v3/stats/all/teams`, {
        params: { size, page, filter, search },
    });
};

export const getTeamStats = (
    teamId: string,
    params: { filter: DurationFilter }
) => {
    console.log("🚀 ~ file: analytic.service.tsx ~ getUserStats");

    return httpClient.get(`/api/v3/stats/team/${teamId}`, {
        params,
    });
};

export const getAlbumStats = (
    albumId: string,
    params: {
        filter: DurationFilter;
    }
) => {
    console.log("🚀 ~ file: analytic.service.tsx ~ getAlbumStats");

    return httpClient.get(`/api/v3/stats/album/${albumId}`, {
        params,
    });
};

export const getTeamAlbumStats = (
    albumId: string,
    teamId: string,
    params: {
        filter: DurationFilter;
    }
) => {
    console.log("🚀 ~ file: analytic.service.tsx ~ getTeamAlbumStats");

    return httpClient.get(`/api/v3/stats/team/${teamId}/album/${albumId}`, {
        params,
    });
};

export const getAllStats = (filter: DurationFilter) => {
    console.log("🚀 ~ file: analytic.service.tsx ~ getAllStats");

    return httpClient.get(`/api/v3/stats/all`, {
        params: { filter },
    });
};
