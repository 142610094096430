import React, { useContext, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import FilterSelector from "../../core/component/FilterSelector";
import { AuthContext } from "../../core/context/auth";
import {
    DurationFilter,
    getAllStats,
} from "../../core/services/analytic.service";
import AlbumHistory from "./AlbumHistory";
import Analytic from "./Analytic";
import CallSession from "./CallSession";
import MobileAndWebUsage from "./MobileAndWebUsage";
import SiteStat from "./SiteStat";
import TeamStat from "./TeamStat";

const Analytics = () => {
    const { authState } = useContext(AuthContext);

    const history = useHistory();

    const [selectedDuration, setSelectedDuration] = useState<DurationFilter>();

    const [analyticsState, setAnalyticsState] = useState<any>();

    useEffect(() => {
        if (!authState.user.attributes.is_admin) {
            const teamRole = authState.teamRole;
            if (teamRole === "admin") {
                history.push(
                    "/settings/team-analytics/" +
                        authState.user.relationships.team.id
                );
            } else {
                history.push("/settings/user-analytics/" + authState.user.id);
            }
        } else {
            if (selectedDuration) {
                setAnalyticsState(null);
                getAllStats(selectedDuration).then((resp) => {
                    setAnalyticsState(resp.data);
                });
            }
        }
    }, [selectedDuration]);

    return (
        <div className="container text-white pr-10">
            <div className="flex justify-end mt-6">
                <FilterSelector
                    onChange={(value: DurationFilter) => {
                        setSelectedDuration(value);
                    }}
                />
            </div>
            <div className={`${analyticsState ? "hidden" : "flex"} text-white`}>
                Loading...
            </div>
            <div
                className={`space-y-12 mb-20 ${
                    analyticsState ? "visible" : "invisible"
                }`}
            >
                <Analytic
                    analyticsState={analyticsState}
                    selectedDuration={selectedDuration}
                />

                <MobileAndWebUsage analyticsState={analyticsState} />

                <CallSession analyticsState={analyticsState} />

                <AlbumHistory selectedDuration={selectedDuration} />

                <SiteStat selectedDuration={selectedDuration} />

                <TeamStat selectedDuration={selectedDuration} />
            </div>
        </div>
    );
};

export default Analytics;
