import React, { useContext, useEffect, useState } from "react";
import AlbumItem from "../dashboard/AlbumItem";
import { ArrowLeftIcon } from "@heroicons/react/solid";
import { useHistory } from "react-router-dom";
import {
    getTeamGlobalAlbums,
    getUserRecentAlbums,
} from "../../core/services/analytic.service";
import { AuthContext } from "../../core/context/auth";
import { uniqBy } from "lodash";
import RowLoader from "../../core/component/RowLoader";

const GlobalAlbumsSeeAll = () => {
    const history = useHistory();

    const { authState } = useContext(AuthContext);

    const [albums, setAlbums] = useState<any>([]);

    const [page, setPage] = useState(1);

    const [loading, setLoading] = useState(false);

    const [isEnd, setIsEnd] = useState(false);

    const [triggerFetch, setTriggerFetch] = useState(0);

    const searchParams = new URLSearchParams(history.location.search);

    const [teamId] = useState(searchParams.get("teamId"));

    const [userId] = useState(searchParams.get("userId") || authState.user.id);

    useEffect(() => {
        if (isEnd) {
            return;
        }
        if (!loading) {
            setLoading(true);
            let request = null;

            if (teamId && teamId !== null && teamId !== "null") {
                request = getTeamGlobalAlbums(teamId, 50, "year", page);
            } else {
                request = getUserRecentAlbums(userId, 50, "year", page);
            }
            request
                .then((resp: any) => {
                    const data =
                        resp.data.members || resp.data.albums || resp.data;

                    setAlbums((currentState: any) => {
                        return uniqBy([...currentState, ...data], (x) => x.id);
                    });
                    setLoading(false);

                    setIsEnd(data.length === 0);

                    setPage((currentState) => {
                        return currentState + 1;
                    });
                })
                .catch((err) => {
                    console.log("🚀 ~ err", err);
                });
        }
    }, [triggerFetch]);

    useEffect(() => {
        const scrollTracking = () => {
            if (
                window.innerHeight + window.scrollY + 100 >=
                document.body.scrollHeight
            ) {
                setTriggerFetch((currentState) => {
                    return currentState + 1;
                });
            }
        };

        window.addEventListener("scroll", scrollTracking);

        return () => {
            window.removeEventListener("scroll", scrollTracking);
        };
    }, []);

    return (
        <div className="container">
            <div className="flex items-center gap-4 text-white">
                <ArrowLeftIcon
                    className="w-6 cursor-pointer"
                    onClick={() => history.goBack()}
                />

                <div className="text-xl">Josh Phegan Digital Albums</div>
            </div>
            <div className=" md:pt-16 pr-10 space-y-2 md:space-y-6">
                <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-x-6 md:gap-x-8 lg:gap-x-10 gap-y-12 md:gap-y-16 lg:gap-y-20">
                    {albums.map((album: any) => {
                        return (
                            <div
                                key={album.id}
                                className="aspect-w-16 aspect-h-9"
                            >
                                <AlbumItem
                                    album={album}
                                    analytics={true}
                                    teamId={teamId}
                                />
                            </div>
                        );
                    })}
                </div>
            </div>
            {loading ? (
                <div className=" py-10 space-y-6">
                    <RowLoader />
                    <RowLoader />
                </div>
            ) : null}
        </div>
    );
};

export default GlobalAlbumsSeeAll;
