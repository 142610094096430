import React, { FormEvent, useState } from "react";
import { resetPasswordRequest } from "../../core/services/auth.service";
import { toast } from "react-toastify";
import Spinner from "../../core/component/Spinner";

const CreateNewPassword = () => {
    const [formData, setFormData] = useState({
        email: "",
        password: "",
        password_confirmation: "",
        token: "",
    });

    const onSubmit = (ev: FormEvent) => {
        ev.preventDefault();
        setSubmitting(true);

        resetPasswordRequest({
            ...formData,
            token:
                new URLSearchParams(window.location.search).get("token") ?? "",
        })
            .then((resp) => {
                toast.success(
                    "New password saved successfully. You will be redirected to sign in form now."
                );
                setTimeout(() => {
                    window.location.assign("/");
                }, 2000);
            })
            .finally(() => {
                setSubmitting(false);
            });
    };

    const [submitting, setSubmitting] = useState(false);

    return (
        <>
            <h2 className="text-3xl font-extrabold text-white">
                Create New Password
            </h2>
            <small className="text-white mb-6 block">
                Please type your new password below and click Save.
            </small>
            <form action="#" className="space-y-6" onSubmit={onSubmit}>
                <div className="space-y-1">
                    <label
                        htmlFor="email"
                        className="block text-base font-thin text-gray-300"
                    >
                        Email address
                    </label>
                    <div className="mt-1">
                        <input
                            id="email"
                            name="email"
                            type="email"
                            autoComplete="email"
                            required
                            className="appearance-none block w-full bg-transparent px-3 py-2 border text-white border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-jp-blue focus:border-jp-blue sm:text-sm"
                            onChange={(ev) => {
                                setFormData((currentState) => {
                                    return {
                                        ...currentState,
                                        email: ev.target.value,
                                    };
                                });
                            }}
                        />
                    </div>
                </div>

                <div className="space-y-1">
                    <label
                        htmlFor="password"
                        className="block text-base font-thin text-gray-300"
                    >
                        Password
                    </label>
                    <div className="mt-1">
                        <input
                            id="password"
                            name="password"
                            type="password"
                            autoComplete="current-password"
                            required
                            className="appearance-none block bg-transparent text-white w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-jp-blue focus:border-jp-blue sm:text-sm"
                            onChange={(ev) => {
                                setFormData((currentState) => {
                                    return {
                                        ...currentState,
                                        password: ev.target.value,
                                    };
                                });
                            }}
                        />
                    </div>
                </div>

                <div className="space-y-1">
                    <label className="block text-base font-thin text-gray-300">
                        Confirm Password
                    </label>
                    <div className="mt-1">
                        <input
                            name="password_confirmation"
                            type="password"
                            autoComplete="current-password"
                            required
                            className="appearance-none block bg-transparent text-white w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-jp-blue focus:border-jp-blue sm:text-sm"
                            onChange={(ev) => {
                                setFormData((currentState) => {
                                    return {
                                        ...currentState,
                                        password_confirmation: ev.target.value,
                                    };
                                });
                            }}
                        />
                    </div>
                </div>

                <button
                    type="submit"
                    className="w-full flex justify-center py-2  rounded-md text-lg font-light bg-white items-center gap-4"
                    disabled={submitting}
                >
                    Save New Password {submitting && <Spinner />}
                </button>
            </form>
        </>
    );
};

export default CreateNewPassword;
