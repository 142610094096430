// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.component_sidebarItem__EADuN {
  display: flex;
  cursor: pointer;
  align-items: center;
  gap: 1rem;
  padding-left: 1.5rem;
  font-size: 1.125rem;
  line-height: 1.75rem;
  letter-spacing: 0.025em;
  --tw-text-opacity: 1;
  color: rgb(156 163 175 / var(--tw-text-opacity));
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, -webkit-backdrop-filter;
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, backdrop-filter;
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, backdrop-filter, -webkit-backdrop-filter;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 150ms;
}.component_sidebarItem__EADuN:hover {
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
}.component_sidebarItem__EADuN {
  border-left: 3px transparent solid;
}
.component_sidebarItem__EADuN:hover {
  border-left: 3px #00a2bc solid;
}
.component_sidebarItem__EADuN:hover svg path {
  stroke: #fff;
  transition: all;
}`, "",{"version":3,"sources":["webpack://./src/app/core/component/index.module.scss"],"names":[],"mappings":"AACI;EAAA,aAAA;EAAA,eAAA;EAAA,mBAAA;EAAA,SAAA;EAAA,oBAAA;EAAA,mBAAA;EAAA,oBAAA;EAAA,uBAAA;EAAA,oBAAA;EAAA,gDAAA;EAAA,gKAAA;EAAA,wJAAA;EAAA,iLAAA;EAAA,wDAAA;EAAA;AAAA,CAAA;EAAA,oBAAA;EAAA;AAAA,CADJ;EAGI,kCAAA;AAAJ;AAEI;EACI,8BAAA;AAAR;AAEQ;EACI,YAAA;EACA,eAAA;AAAZ","sourcesContent":[".sidebarItem {\n    @apply text-gray-400 hover:text-white cursor-pointer transition flex gap-4 items-center text-lg tracking-wide pl-6;\n\n    border-left: 3px transparent solid;\n\n    &:hover {\n        border-left: 3px #00a2bc solid;\n\n        svg path {\n            stroke: #fff;\n            transition: all;\n        }\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"sidebarItem": `component_sidebarItem__EADuN`
};
export default ___CSS_LOADER_EXPORT___;
