import React, { useEffect, useState } from "react";
import { useRouteMatch } from "react-router-dom";
import FilterSelector from "../../core/component/FilterSelector";
import {
    DurationFilter,
    getTeamStats,
} from "../../core/services/analytic.service";
import AlbumHistory from "./AlbumHistory";
import Analytic from "./Analytic";
import CallSession from "./CallSession";
import MobileAndWebUsage from "./MobileAndWebUsage";
import SiteStat from "./SiteStat";

const TeamAnalytics = () => {
    const { params } = useRouteMatch<{ teamId: string }>();
    const { teamId } = params;

    const [selectedDuration, setSelectedDuration] = useState<DurationFilter>();

    const [analyticsState, setAnalyticsState] = useState<any>();

    const [back] = useState(
        new URLSearchParams(window.location.search).get("back") ?? 0
    );

    useEffect(() => {
        if (teamId && selectedDuration) {
            setAnalyticsState(null);

            getTeamStats(teamId, { filter: selectedDuration }).then((resp) => {
                setAnalyticsState(resp.data);
            });
        }
    }, [teamId, selectedDuration]);
    return (
        <div className="container text-white pr-10">
            <div className="flex justify-end mt-6">
                <FilterSelector
                    onChange={(value: DurationFilter) => {
                        setSelectedDuration(value);
                    }}
                />
            </div>
            <div className={`${analyticsState ? "hidden" : "flex"} text-white`}>
                Loading...
            </div>
            <div
                className={`space-y-12 mb-20 ${
                    analyticsState ? "visible" : "invisible"
                }`}
            >
                <Analytic
                    analyticsState={analyticsState?.stats}
                    selectedDuration={selectedDuration}
                    back={back}
                    title={analyticsState?.attributes.name}
                />

                <MobileAndWebUsage analyticsState={analyticsState} />

                <CallSession analyticsState={analyticsState} />

                <AlbumHistory
                    teamId={teamId}
                    selectedDuration={selectedDuration}
                />

                <SiteStat teamId={teamId} selectedDuration={selectedDuration} />
            </div>
        </div>
    );
};

export default TeamAnalytics;
