import { groupBy, map, sumBy } from "lodash";
import moment from "moment";
import { useEffect, useState } from "react";
import { getInvoices } from "../../core/services/auth.service";
import styles from "./index.module.scss";

const Invoices = () => {
    const [invoices, setInvoices] = useState<any>([]);

    const fetchInvoices = async () => {
        try {
            const resp = await getInvoices();
            if (resp?.data) {
                const mappedInvoices = map(
                    groupBy(
                        resp.data.map((invoice: any) => {
                            return {
                                ...invoice,
                                key: moment(
                                    invoice?.attributes?.created_at
                                ).format("MMM yyyy"),
                            };
                        }),
                        (x) => x.key
                    ),
                    (invoices, date) => {
                        return {
                            date,
                            invoices,
                            sum: sumBy(
                                invoices,
                                (x) => x?.attributes?.total
                            ).toFixed(2),
                            currency: invoices?.[0]?.attributes?.currency,
                        };
                    }
                );

                setInvoices(mappedInvoices);
            }
        } catch (e) {
            console.error(e);
        }
    };

    useEffect(() => {
        fetchInvoices();
    }, []);

    return (
        <div className={"mt-4"}>
            <div>
                <div className={styles.formLabel}>previous invoices</div>
                <div className="space-y-4">
                    <div
                        className={
                            "flex justify-between border-b border-gray-600"
                        }
                    >
                        <div>Description</div>
                        <div>Amount</div>
                    </div>
                    {!invoices.length && <div>No invoices available.</div>}
                    {invoices.map((invoice: any) => {
                        return (
                            <div
                                key={invoice.date}
                                className={
                                    "flex justify-between items-center border-b border-gray-600 pb-2"
                                }
                            >
                                <div className={""}>
                                    <div>
                                        <div>Monthly payment plan</div>
                                        <div className="text-sm text-gray-400">
                                            {invoice.date}
                                        </div>
                                    </div>
                                </div>
                                <div>
                                    {invoice.currency}
                                    {invoice.sum}
                                </div>
                            </div>
                        );
                    })}
                </div>
            </div>
        </div>
    );
};

export default Invoices;
