// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.team-management_pill__fOjmi {

    cursor: pointer;

    border-radius: 0.75rem;

    border-width: 1px;

    --tw-border-opacity: 1;

    border-color: rgb(41 168 232 / var(--tw-border-opacity));

    padding-left: 0.5rem;

    padding-right: 0.5rem;

    padding-top: 0.25rem;

    padding-bottom: 0.25rem;

    font-size: 0.75rem;

    line-height: 1rem;

    --tw-text-opacity: 1;

    color: rgb(41 168 232 / var(--tw-text-opacity))
}

.team-management_activePill__e8lGt {

    cursor: pointer;

    border-radius: 0.75rem;

    border-width: 1px;

    --tw-border-opacity: 1;

    border-color: rgb(41 168 232 / var(--tw-border-opacity));

    --tw-bg-opacity: 1;

    background-color: rgb(41 168 232 / var(--tw-bg-opacity));

    padding-left: 0.5rem;

    padding-right: 0.5rem;

    padding-top: 0.25rem;

    padding-bottom: 0.25rem;

    font-size: 0.75rem;

    line-height: 1rem;

    --tw-text-opacity: 1;

    color: rgb(255 255 255 / var(--tw-text-opacity))
}`, "",{"version":3,"sources":["webpack://./src/app/modules/team-management/index.module.scss"],"names":[],"mappings":"AACI;;IAAA,eAAA;;IAAA,sBAAA;;IAAA,iBAAA;;IAAA,sBAAA;;IAAA,wDAAA;;IAAA,oBAAA;;IAAA,qBAAA;;IAAA,oBAAA;;IAAA,uBAAA;;IAAA,kBAAA;;IAAA,iBAAA;;IAAA,oBAAA;;IAAA;AAAA;;AAIA;;IAAA,eAAA;;IAAA,sBAAA;;IAAA,iBAAA;;IAAA,sBAAA;;IAAA,wDAAA;;IAAA,kBAAA;;IAAA,wDAAA;;IAAA,oBAAA;;IAAA,qBAAA;;IAAA,oBAAA;;IAAA,uBAAA;;IAAA,kBAAA;;IAAA,iBAAA;;IAAA,oBAAA;;IAAA;AAAA","sourcesContent":[".pill {\n    @apply rounded-xl border border-jp-blue text-jp-blue px-2 py-1 text-xs cursor-pointer;\n}\n\n.activePill {\n    @apply rounded-xl border border-jp-blue bg-jp-blue text-white px-2 py-1 text-xs cursor-pointer;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"pill": `team-management_pill__fOjmi`,
	"activePill": `team-management_activePill__e8lGt`
};
export default ___CSS_LOADER_EXPORT___;
