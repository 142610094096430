import {
    PencilIcon,
    UploadIcon,
    VideoCameraIcon,
    ViewListIcon,
} from "@heroicons/react/solid";
import React, { useContext, useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import { AuthContext } from "../../core/context/auth";
import {
    deleteTeamAlbum,
    getTeamAlbums,
} from "../../core/services/album.service";
import Swal from "sweetalert2";

const ContentMangement = () => {
    const { authState } = useContext(AuthContext);

    const history = useHistory();

    const [teamAlbums, setTeamAlbums] = useState([]);

    useEffect(() => {
        if (!authState.user.relationships.team) {
            toast.error(
                "You are not a member of any team yet. Please create a team before creating content."
            );
            history.push("/settings/team-management");
        } else {
            getTeamAlbums(authState.user.relationships.team.id).then((resp) => {
                setTeamAlbums(
                    resp.data.map((x: any) => {
                        return {
                            ...x,
                            status: x.relationships.videos
                                .map(
                                    (y: any) => y.attributes.transcoding_status
                                )
                                .includes("pending")
                                ? "processing"
                                : "complete",
                        };
                    })
                );
            });
        }
    }, []);
    return (
        <div className="container text-white py-16 space-y-12">
            <div>
                <Link
                    to={"/settings/content-management/form"}
                    className="bg-jp-blue py-2 px-3 rounded opacity-90 hover:opacity-100"
                >
                    <UploadIcon className="w-4 inline-block" /> CREATE NEW ALBUM
                </Link>
            </div>

            <div className="space-y-6">
                {!teamAlbums.length && <div>No albums available.</div>}
                {teamAlbums.map((album: any, index: number) => {
                    return (
                        <div key={album.id} className="flex gap-6 items-center">
                            <div className="text-gray-400">{index + 1}.</div>
                            <div>
                                <VideoCameraIcon className="w-6" />
                            </div>
                            <div className="w-2/5 truncate">
                                <Link
                                    to={`/albums/${album.id}`}
                                    className={"hover:text-jp-blue"}
                                >
                                    {album.attributes.title}
                                </Link>
                            </div>
                            <div
                                className={`px-2 uppercase w-30 ${
                                    album.status === "complete"
                                        ? "text-green-600"
                                        : "text-yellow-700"
                                }`}
                            >
                                {album.status}
                            </div>
                            <div className="flex gap-1 items-center bg-gray-300 rounded text-jp-black-600 px-2">
                                {album.relationships.videos.length}
                                <ViewListIcon className="w-4" />
                            </div>
                            <div className="flex gap-4 items-center">
                                <Link
                                    to={
                                        "/settings/content-management/form/" +
                                        album.id
                                    }
                                    className="flex gap-2 items-center border rounded py-1 px-4"
                                >
                                    <PencilIcon className="w-4" />
                                    Edit
                                </Link>
                                <Link
                                    to={`/albums/${album.id}/quiz-studio`}
                                    className="flex gap-2 items-center border rounded py-1 px-4"
                                >
                                    {album.relationships?.quiz?.length
                                        ? "Update"
                                        : "Create"}{" "}
                                    Quiz
                                </Link>
                                <button
                                    className="flex gap-2 items-center  text-red-500"
                                    onClick={() => {
                                        Swal.fire({
                                            text: "Are you you want to delete team album?",
                                            showCancelButton: true,
                                            confirmButtonText: "Delete",
                                            confirmButtonColor:
                                                "rgb(239, 68, 68)",
                                        }).then((result) => {
                                            if (result.isConfirmed) {
                                                deleteTeamAlbum(
                                                    authState.user.relationships
                                                        .team.id,
                                                    album.id
                                                ).then(() => {
                                                    toast.success(
                                                        "Team album deleted successfully."
                                                    );
                                                    setTeamAlbums(
                                                        (currentState) => {
                                                            return [
                                                                ...currentState.filter(
                                                                    (x: any) =>
                                                                        x.id !==
                                                                         album.id
                                                                ),
                                                            ];
                                                        }
                                                    );
                                                });
                                            }
                                        });
                                    }}
                                >
                                    DELETE
                                </button>
                            </div>
                        </div>
                    );
                })}
            </div>
        </div>
    );
};

export default ContentMangement;
