import { XIcon } from "@heroicons/react/solid";
import squares from "../../../assets/images/squares.svg";
import styles from "./index.module.scss";
import TypeAndCategory from "./TypeAndCategory";

const ResourceFilters: React.FC<{
    resourceTypes: any[];
    resourceCategories: any[];
    selectedTypeAndCategory: TypeAndCategory;
    setSelectedTypeAndCategory: React.Dispatch<
        React.SetStateAction<TypeAndCategory>
    >;
}> = (props) => {
    return (
        <>
            <div className="space-y-2">
                <div className={styles.listTitle}>
                    <img src={squares} alt="" />
                    Type
                </div>

                {props.resourceTypes.map((type: any) => {
                    return (
                        <div
                            key={type.id}
                            className={`${styles.listItem}  ${
                                props.selectedTypeAndCategory.type_id ===
                                    type.id && styles.active
                            }`}
                            onClick={() => {
                                props.setSelectedTypeAndCategory(
                                    (currentState) => {
                                        return {
                                            ...currentState,
                                            type_id: type.id,
                                            page: 1,
                                        };
                                    }
                                );
                            }}
                        >
                            {type.attributes.title}
                        </div>
                    );
                })}
            </div>
            <div className="space-y-2">
                <div className={styles.listTitle}>
                    <img src={squares} alt="" />
                    Categories
                </div>
                {props.resourceCategories.map((category: any) => {
                    return (
                        <div
                            key={category.id}
                            className={`${styles.listItem}  ${
                                props.selectedTypeAndCategory.category_id ===
                                    category.id && styles.active
                            }`}
                            onClick={() => {
                                props.setSelectedTypeAndCategory(
                                    (currentState) => {
                                        return {
                                            ...currentState,
                                            category_id: category.id,
                                            page: 1,
                                        };
                                    }
                                );
                            }}
                        >
                            {category.attributes.title}
                        </div>
                    );
                })}
            </div>
            <div
                className={
                    styles.listItem + " flex gap-2 uppercase items-center"
                }
                onClick={() => {
                    props.setSelectedTypeAndCategory((currentState) => {
                        return {
                            ...currentState,
                            category_id: null,
                            type_id: null,
                            page: 1,
                        };
                    });
                }}
            >
                <XIcon className="w-4" />
                Reset filters
            </div>
        </>
    );
};

export default ResourceFilters;
