import Pusher from "pusher-js";

const pusherClient = new Pusher(process.env.REACT_APP_PUSHER_KEY || "", {
    cluster: process.env.REACT_APP_PUSHER_CLUSTER || "",
    forceTLS: true,
});

export default {
    client: pusherClient,
    pusherChannel: process.env.REACT_APP_PUSHER_CHANNEL || "",
    pusherEvent: process.env.REACT_APP_PUSHER_EVENT || "",
};
