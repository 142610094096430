import { isEmpty } from "lodash";
import React, { useEffect, useState } from "react";
import Select, { components, DropdownIndicatorProps } from "react-select";
import { DarkThemeDropDown } from "../helpers/dropdownStyle";

const FilterSelector = ({ onChange }: any) => {
    const filters = [
        { label: "Today", value: "today" },
        { label: "Week", value: "week" },
        { label: "Month to date", value: "month" },
        { label: "Current Quarter", value: "quarter" },
        { label: "Year to date", value: "year" },
    ];

    const [defaultValue, setDefaultValue] = useState<any>();

    useEffect(() => {
        const localDefaultValue = JSON.parse(
            localStorage.getItem("DurationFilter") || JSON.stringify(filters[0])
        );

        if (!isEmpty(localDefaultValue)) {
            setDefaultValue(localDefaultValue);
            onChange(localDefaultValue.value);
        } else {
            setDefaultValue(filters[0]);
        }
    }, []);

    const DropdownIndicator = (props: DropdownIndicatorProps<any, true>) => {
        return (
            <components.DropdownIndicator {...props}>
                <div className="">
                    <svg
                        width="21"
                        height="21"
                        viewBox="0 0 21 21"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path
                            d="M16.0861 6.73877H18.4961"
                            stroke="white"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                        />
                        <path
                            d="M2.49417 6.73877L10.6484 6.73877"
                            stroke="white"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                        />
                        <path
                            d="M10.9961 6.73877C10.9961 8.11948 12.1154 9.23877 13.4961 9.23877C14.8768 9.23877 15.9961 8.11948 15.9961 6.73877C15.9961 5.35806 14.8768 4.23877 13.4961 4.23877C12.1154 4.23877 10.9961 5.35806 10.9961 6.73877Z"
                            stroke="white"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                        />
                        <path
                            d="M4.93338 14.2617H2.52344"
                            stroke="white"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                        />
                        <path
                            d="M18.5254 14.2617L10.3711 14.2617"
                            stroke="white"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                        />
                        <path
                            d="M10.0234 14.2617C10.0234 15.6424 8.90415 16.7617 7.52344 16.7617C6.14273 16.7617 5.02344 15.6424 5.02344 14.2617C5.02344 12.881 6.14273 11.7617 7.52344 11.7617C8.90415 11.7617 10.0234 12.881 10.0234 14.2617Z"
                            stroke="white"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                        />
                    </svg>
                </div>
            </components.DropdownIndicator>
        );
    };

    return defaultValue ? (
        <div style={{ width: 200 }}>
            <Select
                components={{ DropdownIndicator }}
                defaultValue={defaultValue}
                options={filters}
                onChange={(filter: any) => {
                    onChange(filter.value);
                    localStorage.setItem(
                        "DurationFilter",
                        JSON.stringify(filter)
                    );
                }}
                styles={DarkThemeDropDown}
            />
        </div>
    ) : null;
};

export default FilterSelector;
