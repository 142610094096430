import React, { useContext, useEffect, useState } from "react";
import { getTeamAlbums } from "../../core/services/album.service";
import styles from "./index.module.scss";
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Navigation } from "swiper";
import {
    ArrowCircleRightIcon,
    ChevronLeftIcon,
    ChevronRightIcon,
} from "@heroicons/react/solid";
import AlbumItem from "./AlbumItem";
import { Link } from "react-router-dom";
import { AuthContext } from "../../core/context/auth";

SwiperCore.use([Navigation]);

const TeamAlbumRow = ({ loading }: any) => {
    const [albums, setAlbums] = useState([]);

    const { authState } = useContext(AuthContext);

    const [isCompleted, setIsCompleted] = useState(false);

    useEffect(() => {
        if (authState?.user?.relationships?.team?.id) {
            getTeamAlbums(authState?.user?.relationships?.team?.id).then(
                (resp) => {
                    setAlbums(resp.data);
                    setIsCompleted(true);
                }
            );
        }
    }, []);

    return (
        <>
            {authState?.user?.relationships?.team?.id ? (
                <>
                    {albums.length ? (
                        <div className={styles.itemsRow}>
                            <div className="space-y-4 relative group">
                                <div className="flex justify-between -mb-8 lg:-mb-20">
                                    <div className="text-gray-500 pl-3 uppercase flex-1">
                                        Team Albums
                                    </div>
                                    <div className="text-white relative z-10 opacity-100 md:opacity-0 group-hover:opacity-100 transition">
                                        <Link
                                            to="/team-albums"
                                            className="flex items-center gap-2"
                                        >
                                            <span>View All</span>
                                            <ArrowCircleRightIcon className="w-4" />
                                        </Link>
                                    </div>

                                    {/* //controls */}
                                    <div className="new-button-prev cursor-pointer hover:bg-gray-600 opacity-0 transition group-hover:opacity-100 hover:bg-opacity-40 absolute left-0 top-2/4 -mt-10 z-10">
                                        <ChevronLeftIcon className="w-16 text-gray-500 hover:text-white" />
                                    </div>

                                    <div className="new-button-next cursor-pointer hover:bg-gray-600 opacity-0 transition group-hover:opacity-100 hover:bg-opacity-40 absolute right-0 top-2/4 -mt-10 z-10">
                                        <ChevronRightIcon className="w-16 text-gray-500 hover:text-white" />
                                    </div>
                                </div>
                                <Swiper
                                    navigation={{
                                        nextEl: ".new-button-next",
                                        prevEl: ".new-button-prev",
                                    }}
                                    slidesPerView="auto"
                                    allowTouchMove={false}
                                >
                                    {albums.map((album: any, index: number) => {
                                        return (
                                            <SwiperSlide
                                                key={album.id}
                                                className={`my-8 lg:my-20 mx-3 hover:scale-125 transform-gpu transition rounded  shadow-2xl ${
                                                    styles.zoomer
                                                } ${styles.itemInRow} ${
                                                    index === 0 &&
                                                    " origin-left"
                                                } ${
                                                    index ===
                                                        albums.length - 1 &&
                                                    " origin-right"
                                                }`}
                                            >
                                                <AlbumItem
                                                    album={album}
                                                    teamAlbum={true}
                                                />
                                            </SwiperSlide>
                                        );
                                    })}
                                </Swiper>
                            </div>
                        </div>
                    ) : (
                        <>{!isCompleted ? loading : null}</>
                    )}
                </>
            ) : null}
        </>
    );
};

export default TeamAlbumRow;
