import httpClient from "../helpers/httpClient";

export const savePlayerProgress = (params: {
    album_id: number | string;
    model_type: string;
    progress: number;
    is_completed: number;
    model_id: number | string;
}) => {
    return httpClient.post("/api/v3/playback/", { ...params, source: "web" });
};
