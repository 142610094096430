import React, { useState } from "react";
import styles from "./../dashboard/index.module.scss";
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Navigation } from "swiper";
import { ChevronLeftIcon, ChevronRightIcon } from "@heroicons/react/solid";
import { Link } from "react-router-dom";

import videoIcon from "./../../../assets/images/video.svg";
import audioIcon from "./../../../assets/images/audio.svg";
import placeholderImage from "./../../../assets/images/placeholder.jpg";
import teamAlbumPlaceholderImage from "./../../../assets/images/team-album-placeholder.jpg";
import { Line } from "rc-progress";
import { uniqueId } from "lodash";

SwiperCore.use([Navigation]);

const AnalyticsAlbumRow = ({
    albums = [],
    teamId,
    canOpenAlbumAnalytics,
    teamAlbum = false,
}: any) => {
    const [randomId] = useState(uniqueId());
    return (
        <div className="space-y-4 relative group">
            <div className="flex justify-between -mb-8 lg:-mb-20">
                {/* //controls */}
                <div
                    className={`new-button-prev${randomId} cursor-pointer hover:bg-gray-600 opacity-0 transition group-hover:opacity-100 hover:bg-opacity-40 absolute left-0 top-2/4 -mt-10 z-10`}
                >
                    <ChevronLeftIcon className="w-16 text-gray-500 hover:text-white" />
                </div>

                <div
                    className={`new-button-next${randomId} cursor-pointer hover:bg-gray-600 opacity-0 transition group-hover:opacity-100 hover:bg-opacity-40 absolute right-0 top-2/4 -mt-10 z-10`}
                >
                    <ChevronRightIcon className="w-16 text-gray-500 hover:text-white" />
                </div>
            </div>
            <Swiper
                navigation={{
                    nextEl: ".new-button-next" + randomId,
                    prevEl: ".new-button-prev" + randomId,
                }}
                slidesPerView="auto"
                allowTouchMove={false}
            >
                {albums.map((album: any, index: number) => {
                    return (
                        <SwiperSlide
                            key={uniqueId()}
                            className={`my-8 lg:my-20 mr-3 hover:scale-125 transform-gpu transition rounded  shadow-2xl ${
                                styles.zoomer
                            } ${styles.itemInRow} ${
                                index === 0 && " origin-left"
                            } ${
                                index === albums.length - 1 && " origin-right"
                            }`}
                        >
                            <Link
                                to={
                                    canOpenAlbumAnalytics && teamId
                                        ? `/settings/album-analytics/${album.id}?teamId=${teamId}`
                                        : `/albums/${album.id}?toHome=0`
                                }
                            >
                                <div
                                    style={{
                                        backgroundImage: `url(${
                                            album.attributes.image ||
                                            (teamAlbum
                                                ? teamAlbumPlaceholderImage
                                                : placeholderImage)
                                        }`,
                                    }}
                                    className={` h-full w-full bg-no-repeat  bg-cover bg-center flex flex-row  items-center px-10 cursor-pointer  rounded`}
                                ></div>

                                <Line
                                    percent={
                                        album.attributes
                                            .average_watch_time_percent
                                    }
                                    strokeColor="#1691D0"
                                />

                                <div
                                    className={`text-white   p-2 h-9 overflow-hidden`}
                                >
                                    <div className={"flex items-center gap-2"}>
                                        <div>
                                            <img
                                                src={
                                                    album.attributes.type ===
                                                    "video"
                                                        ? videoIcon
                                                        : audioIcon
                                                }
                                                alt="Album Type"
                                            />
                                        </div>
                                        <div className={"text-sm truncate"}>
                                            {album.attributes.title}
                                        </div>
                                    </div>
                                </div>
                            </Link>
                        </SwiperSlide>
                    );
                })}
            </Swiper>
        </div>
    );
};

export default AnalyticsAlbumRow;
