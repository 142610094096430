import httpClient from "../helpers/httpClient";

export const getAlbums = (params: any) => {
    return httpClient.get("/api/v3/albums", {
        params,
    });
};

export const getAlbumsByCategoryId = (
    categoryId: number | string,
    payload: any
) => {
    return httpClient.get("/api/v3/albums?category_id=" + categoryId, {
        params: {
            ...payload,
        },
    });
};

export const getAlbumById = (id: string) => {
    return httpClient.get("/api/v3/albums/" + id);
};

export const searchAlbums = (
    text = "",
    size = 10,
    category_id: number | string | null
) => {
    return httpClient.get("/api/v3/search/albums/", {
        params: {
            text,
            size,
            category_id,
        },
    });
};

export const createTeamAlbum = (teamId: string, payload: any) => {
    const formData = new FormData();

    for (var key in payload) {
        formData.append(key, payload[key]);
    }
    return httpClient.post(`/api/v3/teams/${teamId}/albums`, formData);
};

export const updateTeamAlbum = (
    teamId: string,
    albumId: string,
    payload: any
) => {
    const formData = new FormData();

    for (var key in payload) {
        formData.append(key, payload[key]);
    }
    return httpClient.post(
        `/api/v3/teams/${teamId}/albums/${albumId}`,
        formData
    );
};

export const getTeamAlbums = (teamId: string, params = {}) => {
    return httpClient.get(`/api/v3/teams/${teamId}/albums`, {
        params,
    });
};

export const getTeamAlbum = (teamId: string, albumId: string) => {
    return httpClient.get(`/api/v3/teams/${teamId}/albums/${albumId}`);
};

export const deleteTeamAlbum = (teamId: string, albumId: string) => {
    return httpClient.delete(`/api/v3/teams/${teamId}/albums/${albumId}`);
};

export const updateAlbumVideo = (
    teamId: string,
    albumId: string,
    videoId: string,
    payload: any
) => {
    return httpClient.post(
        `/api/v3/teams/${teamId}/albums/${albumId}/videos/${videoId}`,
        payload
    );
};

export const deleteTeamAlbumVideo = (videoId: string) => {
    return httpClient.delete(`/api/v3/videos/${videoId}`);
};

export const saveTeamAlbumTracks = (
    teamId: string,
    albumId: string,
    payload: any,
    onUploadProgress: any
) => {
    const formData = new FormData();

    for (var key in payload) {
        formData.append(key, payload[key]);
    }

    return httpClient.post(
        `/api/v3/teams/${teamId}/albums/${albumId}/videos`,
        formData,
        {
            onUploadProgress,
        }
    );
};
