import React, { useEffect, useState } from "react";
import DataTable, { TableStyles } from "react-data-table-component";
import { useHistory } from "react-router-dom";
import { getTeamsStats } from "../../core/services/analytic.service";
import { debounce, toArray } from "lodash";

const customStyles: TableStyles = {
    headRow: {
        style: {
            background: "#292929",
            color: "#787878",
            fontWeight: "700",
        },
    },
    headCells: {
        style: {
            border: "1px solid rgba(102, 102, 102, 0.08)",
        },
    },
    cells: {
        style: {
            border: "1px solid rgba(102, 102, 102, 0.08)",
        },
    },
    rows: {
        style: {
            background: "#292929",
            color: "#fff",
        },
    },
    pagination: {
        style: {
            background: "#292929",
            color: "#fff",
        },
        pageButtonsStyle: {
            color: "#fff",
            fill: "#fff",
            "&:disabled": {
                cursor: "unset",
                color: "#fff",
                fill: "#fff",
            },
            "&:hover:not(:disabled)": {
                color: "#fff",
                fill: "#fff",
            },
            "&:focus": {
                outline: "none",
                color: "#fff",
                fill: "#fff",
            },
        },
    },
};
const TeamStat = ({ selectedDuration }: any) => {
    const history = useHistory();
    const [columns, setColumns] = useState<any>([]);

    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [totalRows, setTotalRows] = useState(0);

    const [searchTerm, setSearchTerm] = useState("");

    const fetchData = (page = 1, perPage = 10) => {
        setLoading(true);

        getTeamsStats(perPage, page, selectedDuration, searchTerm).then(
            (resp: any) => {
                const collection = toArray(resp.data.teams) as any;
                setData(collection);
                setTotalRows(resp.data.total);
                setLoading(false);
            }
        );
    };

    useEffect(() => {
        if (selectedDuration) {
            fetchData();
        }
    }, [selectedDuration, searchTerm]);

    useEffect(() => {
        // fetchData();
        setColumns([
            {
                name: "Name",
                selector: (row: any) => row.attributes.name,
                sortable: true,
            },
            {
                name: "Members",
                selector: (row: any) => row.attributes.members_count,
                sortable: true,
            },
            {
                name: "Albums",
                selector: (row: any) => row.attributes.albums_count,
                sortable: true,
            },
            {
                name: "JP Albums Playback (min)",
                selector: (row: any) => row.stats.albums_playback_minutes,
                sortable: true,
            },
            {
                name: "Calls",
                selector: (row: any) => row.sessions.calls,
                sortable: true,
            },
            {
                name: "Connects",
                selector: (row: any) => row.sessions.connects,
                sortable: true,
            },
            {
                name: "BAP",
                selector: (row: any) => row.sessions.appointments.bap,
                sortable: true,
            },
            {
                name: "MAP",
                selector: (row: any) => row.sessions.appointments.map,
                sortable: true,
            },
            {
                name: "LAP",
                selector: (row: any) => row.sessions.appointments.lap,
                sortable: true,
            },
        ]);
    }, []);

    return (
        <div>
            <div className="flex justify-between items-center my-4">
                <div className="text-xl">Team Stats</div>

                <input
                    type="text"
                    placeholder="Search"
                    className="appearance-none block  bg-transparent text-white px-3 py-1 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-jp-blue focus:border-jp-blue sm:text-sm w-1/4"
                    onChange={debounce((ev) => {
                        setSearchTerm(ev.target.value);
                    }, 500)}
                />
            </div>
            <div>
                <DataTable
                    columns={columns}
                    pagination
                    data={data}
                    progressPending={loading}
                    paginationServer
                    paginationTotalRows={totalRows}
                    onChangePage={(page) => {
                        fetchData(page);
                    }}
                    onChangeRowsPerPage={(perpage, page) => {
                        fetchData(page, perpage);
                    }}
                    fixedHeader={true}
                    onRowClicked={(row: any) => {
                        history.push(
                            `/settings/team-analytics/${row.id}?back=1&title=${row.attributes.name}`
                        );
                    }}
                    customStyles={customStyles}
                />
            </div>
        </div>
    );
};

export default TeamStat;
