import React from "react";

const ProgressBar = ({
    title,
    percent,
    background = "#2B5F67",
}: {
    title: string;
    percent: number;
    background?: string;
}) => {
    return (
        <div className="flex justify-between bg-gray-500 rounded relative">
            <div
                className="absolute h-full rounded z-0 transition-all"
                style={{
                    width: percent + "%",
                    background,
                }}
            ></div>
            <div className="pl-2 relative">{title}</div>
            <div className="pr-2 relative">{percent?.toFixed()}%</div>
        </div>
    );
};

export default ProgressBar;
