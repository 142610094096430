import React, { useEffect, useState } from "react";
import { Chart, registerables } from "chart.js";
import ProgressBar from "../../core/component/ProgressBar";
import { ArrowLeftIcon } from "@heroicons/react/solid";
import { useHistory } from "react-router-dom";
Chart.register(...registerables);

const Analytic = ({
    analyticsState,
    selectedDuration,
    back,
    title = "",
}: any) => {
    const history = useHistory();
    const [chartState, setChartState] = useState<any>();
    useEffect(() => {
        const ctx = document.getElementById("pieChartCanvas") as any;
        if (ctx && analyticsState) {
            if (chartState) {
                chartState.destroy();
            }
            const chart = new Chart(ctx, {
                type: "doughnut",
                data: {
                    labels: [`Video`, `Audio`],
                    datasets: [
                        {
                            data: [
                                analyticsState?.video_percent,
                                analyticsState?.audio_percent,
                            ],
                            backgroundColor: [
                                "rgba(0, 162, 188, 1)",
                                "rgba(0, 162, 188, 0.4)",
                            ],
                            borderWidth: 0,
                        },
                    ],
                },
                options: {
                    aspectRatio: 1,
                    cutout: "90%",
                    plugins: {
                        tooltip: {
                            callbacks: {
                                label: (tootltipItem) => {
                                    return `${tootltipItem.label}: ${tootltipItem.formattedValue}%`;
                                },
                            },
                        },
                    },
                },
            });
            setChartState(chart);
        }
    }, [analyticsState]);

    return (
        <div className="space-y-6">
            <div className="flex items-center gap-4">
                {back ? (
                    <>
                        <ArrowLeftIcon
                            className="w-6 cursor-pointer"
                            onClick={() => history.goBack()}
                        />
                    </>
                ) : null}

                <div className="text-xl">
                    {title ? <>{title}</> : null} Analytics
                </div>
            </div>
            <div className="space-y-12 border rounded p-6 border-gray-600">
                <div className="flex items-center text-center">
                    <div className="border-r flex-1 border-gray-600">
                        <div>Total time of video views</div>
                        <div className="text-2xl">
                            {analyticsState?.video_playback_minutes} m
                        </div>
                    </div>
                    <div className="border-r flex-1 border-gray-600">
                        <div>Total time of audio listening</div>
                        <div className="text-2xl">
                            {analyticsState?.audio_playback_minutes} m
                        </div>
                    </div>
                    <div className="flex-1">
                        <div>Total time of video views & audio listening</div>
                        <div className="text-2xl">
                            {analyticsState?.total_playback_minutes} m
                        </div>
                    </div>
                </div>

                <div className="flex items-center gap-10">
                    <div className="w-1/4 relative">
                        <div className="absolute pointer-events-none pt-6 z-0 top-0 left-0 bottom-0 right-0 grid place-items-center capitalize">
                            <div>{selectedDuration}</div>
                        </div>
                        <canvas
                            id="pieChartCanvas"
                            width="200"
                            height="200"
                        ></canvas>
                    </div>

                    <div className="space-y-4 flex-grow">
                        <ProgressBar
                            percent={analyticsState?.android_percent}
                            title={"Android"}
                        />
                        <ProgressBar
                            percent={analyticsState?.ios_percent}
                            title={"IOS"}
                        />
                        <ProgressBar
                            percent={analyticsState?.web_percent}
                            title={"Web"}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Analytic;
