import React, { useEffect, useState } from "react";
import { Chart, registerables } from "chart.js";
import ringBG from "./../../../assets/images/ring-bg.png";

Chart.register(...registerables);
const CallSession = ({ analyticsState }: any) => {
    const [chartState, setChartState] = useState<any>();

    useEffect(() => {
        const ctx = document.getElementById("lineChartCanvas") as any;
        if (ctx && analyticsState) {
            const chartData = analyticsState?.sessions?.activity;

            if (chartState) {
                chartState.destroy();
            }
            const chart = new Chart(ctx, {
                type: "bar",
                data: {
                    labels: Object.keys(chartData || {}),
                    datasets: [
                        {
                            label: "Call Session",
                            data: Object.values(chartData || {}),
                            backgroundColor: "rgba(61, 99, 235, 1)",
                        },
                    ],
                },
                options: {
                    scales: {
                        y: {
                            beginAtZero: true,
                            ticks: {
                                precision: 0,
                            },
                        },
                    },
                },
            });

            setChartState(chart);
        }
    }, [analyticsState]);

    return (
        <div className="space-y-6">
            <div className="flex items-center gap-4">
                <div className="text-xl">Call Session</div>
            </div>
            <div className="space-y-12 border rounded p-6 border-gray-600">
                <div>
                    <canvas id="lineChartCanvas"></canvas>
                </div>
                <div className="flex gap-6">
                    <div className="relative">
                        <div className="absolute  left-2/4 top-2/4 transform -translate-x-2/4  -translate-y-2/4 text-center">
                            <div className="text-gray-500">Calls</div>
                            <div>{analyticsState?.sessions?.calls}</div>
                        </div>
                        <img src={ringBG} alt="Ring" />
                    </div>
                    <div className="relative">
                        <div className="absolute  left-2/4 top-2/4 transform -translate-x-2/4  -translate-y-2/4 text-center">
                            <div className="text-gray-500">Connects</div>
                            <div>{analyticsState?.sessions?.connects}</div>
                        </div>
                        <img src={ringBG} alt="Ring" />
                    </div>
                    <div className="relative">
                        <div className="absolute  left-2/4 top-2/4 transform -translate-x-2/4  -translate-y-2/4 text-center">
                            <div className="text-gray-500">Sessions</div>
                            <div>{analyticsState?.sessions?.sessions}</div>
                        </div>
                        <img src={ringBG} alt="Ring" />
                    </div>
                    <div className="relative">
                        <div className="absolute  left-2/4 top-2/4 transform -translate-x-2/4  -translate-y-2/4 text-center">
                            <div className="text-gray-500">BAP</div>
                            <div>
                                {analyticsState?.sessions?.appointments.bap}
                            </div>
                        </div>
                        <img src={ringBG} alt="Ring" />
                    </div>
                    <div className="relative">
                        <div className="absolute  left-2/4 top-2/4 transform -translate-x-2/4  -translate-y-2/4 text-center">
                            <div className="text-gray-500">MAP</div>
                            <div>
                                {analyticsState?.sessions?.appointments.map}
                            </div>
                        </div>
                        <img src={ringBG} alt="Ring" />
                    </div>
                    <div className="relative">
                        <div className="absolute  left-2/4 top-2/4 transform -translate-x-2/4  -translate-y-2/4 text-center">
                            <div className="text-gray-500">LAP</div>
                            <div>
                                {analyticsState?.sessions?.appointments.lap}
                            </div>
                        </div>
                        <img src={ringBG} alt="Ring" />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default CallSession;
