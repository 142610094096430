/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import placeholderImage from "./../../../assets/images/placeholder.jpg";

import videoIcon from "./../../../assets/images/video.svg";
import audioIcon from "./../../../assets/images/audio.svg";
import { Swiper, SwiperSlide } from "swiper/react";

import SwiperCore, { Navigation } from "swiper";

import styles from "./../dashboard/index.module.scss";
import { ChevronLeftIcon, ChevronRightIcon } from "@heroicons/react/solid";
import { isEmpty } from "lodash";
import { Line } from "rc-progress";

declare const window: any;

SwiperCore.use([Navigation]);
const ContentRow = ({
    album,
    activeMedia,
    isTeamAlbum = false,
    handleReachEnd,
}: any) => {
    const [activeIndex, setActiveIndex] = useState(null);
    const [swiper, setSwiper] = useState<any>();

    const [localList, setLocalList] = useState<any>(
        isTeamAlbum
            ? album.relationships.videos.filter(
                  (x: any) => x.attributes.playlist
              )
            : album.relationships.tracks
    );
    const [isMobile] = useState(window.innerWidth < 768);

    useEffect(() => {
        if (!isEmpty(activeMedia)) {
            const index = localList.findIndex(
                (x: any) => x.attributes.title === activeMedia.title
            );
            setActiveIndex(index);
            swiper?.slideTo(index);
        }
    }, [activeMedia]);

    useEffect(() => {
        setLocalList(
            isTeamAlbum
                ? album.relationships.videos.filter(
                      (x: any) => x.attributes.playlist
                  )
                : album.relationships.tracks
        );
    }, [album]);
    return (
        <div>
            {album && localList.length > 1 ? (
                <div className="group relative">
                    {isMobile ? (
                        <>
                            {localList.map((track: any, index: number) => {
                                return (
                                    <div
                                        key={track.id}
                                        className={`aspect-w-16 aspect-h-9 mb-20 
                                                                                  
                                        ${
                                            activeIndex === index &&
                                            "border-4 border-blue-400"
                                        }
                                        `}
                                        onClick={() => {
                                            window
                                                .jwplayer()
                                                .playlistItem(index);
                                        }}
                                    >
                                        <div>
                                            <div
                                                style={{
                                                    backgroundImage: `url(${
                                                        track.mobile_image ||
                                                        album.attributes
                                                            .image ||
                                                        placeholderImage
                                                    }`,
                                                }}
                                                className={`h-full w-full bg-no-repeat  bg-cover bg-bottom flex flex-row  items-center px-10 cursor-pointer `}
                                            ></div>
                                            <Line
                                                percent={
                                                    track.attributes.progress
                                                }
                                                strokeColor="#1691D0"
                                            />
                                            <div
                                                className={`text-white   p-2 h-9 overflow-hidden `}
                                            >
                                                <div
                                                    className={
                                                        "flex items-center gap-2"
                                                    }
                                                >
                                                    <div>
                                                        <img
                                                            src={
                                                                album.attributes
                                                                    .type ===
                                                                "video"
                                                                    ? videoIcon
                                                                    : audioIcon
                                                            }
                                                            alt="Album Type"
                                                        />
                                                    </div>
                                                    <div
                                                        className={
                                                            "text-sm truncate"
                                                        }
                                                    >
                                                        {track.attributes.title}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                );
                            })}
                        </>
                    ) : (
                        <>
                            <div className="flex justify-between -mb-20">
                                {/* //controls */}
                                <div className="new-button-prev cursor-pointer hover:bg-gray-600 opacity-100 transition group-hover:opacity-100 hover:bg-opacity-40 absolute left-0 top-2/4 -mt-10 lg:-mt-16 z-10">
                                    <ChevronLeftIcon className="w-16 text-gray-500 hover:text-white" />
                                </div>

                                <div className="new-button-next cursor-pointer hover:bg-gray-600 opacity-100 transition group-hover:opacity-100 hover:bg-opacity-40 absolute right-0 top-2/4 -mt-10 lg:-mt-16 z-10">
                                    <ChevronRightIcon className="w-16 text-gray-500 hover:text-white" />
                                </div>
                            </div>

                            <Swiper
                                navigation={{
                                    nextEl: ".new-button-next",
                                    prevEl: ".new-button-prev",
                                }}
                                slidesPerView={"auto"}
                                allowTouchMove={false}
                                onSwiper={(swiper) => {
                                    setSwiper(swiper);
                                }}
                                onReachEnd={handleReachEnd}
                            >
                                {localList.map((track: any, index: number) => {
                                    return (
                                        <SwiperSlide
                                            key={track.id}
                                            className={`my-20 mx-3 hover:scale-125 transform-gpu transition rounded  shadow-2xl ${
                                                styles.zoomer
                                            }  ${styles.itemInRow}
                                        ${index === 0 && " origin-left"} ${
                                                index ===
                                                    album.relationships.tracks
                                                        .length -
                                                        1 && " origin-right"
                                            }
                                        ${
                                            activeIndex === index &&
                                            "border-4 border-blue-400"
                                        }
                                        `}
                                            onClick={() => {
                                                window
                                                    .jwplayer()
                                                    .playlistItem(index);
                                            }}
                                        >
                                            <div
                                                style={{
                                                    backgroundImage: `url(${
                                                        track.image ||
                                                        album.attributes
                                                            .image ||
                                                        placeholderImage
                                                    }`,
                                                }}
                                                className={`h-full w-full bg-no-repeat  bg-cover bg-bottom flex flex-row  items-center px-10 cursor-pointer `}
                                            ></div>
                                            <Line
                                                percent={
                                                    track.attributes.progress
                                                }
                                                strokeColor="#1691D0"
                                            />
                                            <div
                                                className={`text-white   p-2 h-9 overflow-hidden `}
                                            >
                                                <div
                                                    className={
                                                        "flex items-center gap-2"
                                                    }
                                                >
                                                    <div>
                                                        <img
                                                            src={
                                                                album.attributes
                                                                    .type ===
                                                                "video"
                                                                    ? videoIcon
                                                                    : audioIcon
                                                            }
                                                            alt="Album Type"
                                                        />
                                                    </div>
                                                    <div
                                                        className={
                                                            "text-sm truncate"
                                                        }
                                                    >
                                                        {track.attributes.title}
                                                    </div>
                                                </div>
                                            </div>
                                        </SwiperSlide>
                                    );
                                })}
                            </Swiper>
                        </>
                    )}
                </div>
            ) : null}
        </div>
    );
};

export default ContentRow;
