import httpClient from "../helpers/httpClient";

export const getResourceType = () => {
    return httpClient.get("/api/v3/resources/types");
};

export const getResourceCategories = () => {
    return httpClient.get("/api/v3/resources/categories");
};

export const getResources = (selectedTypeAndCategory: any) => {
    return httpClient.get("/api/v3/resources", {
        params: { ...selectedTypeAndCategory },
    });
};
