/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import { useState, useEffect, useRef } from "react";
type Item = {
    label?: string;
    action?: (value?: any) => void;
    value?: string;
    isCaution?: boolean;
};
interface Props {
    items?: Item[];
    label?: string;
    defaultValue: string;
}
const Dropdown: React.FC<Props> = ({
    label = "Options",
    items,
    defaultValue,
}) => {
    const [isOpen, setIsOpen] = useState(false);

    const [value, setValue] = useState(defaultValue);

    const humanize = (str: string) => {
        var i,
            frags = str.split("_");
        for (i = 0; i < frags.length; i++) {
            frags[i] = frags[i].charAt(0).toUpperCase() + frags[i].slice(1);
        }
        return frags.join(" ");
    };

    const wrapperRef = useRef<any>(null);
    useEffect(() => {
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, []);

    const handleClickOutside = (event: any) => {
        if (wrapperRef?.current && !wrapperRef.current.contains(event.target)) {
            setIsOpen(false);
        }
    };

    return (
        <div className="relative inline-block text-left" ref={wrapperRef}>
            <div>
                <button
                    type="button"
                    className=" capitalize inline-flex w-full justify-center gap-x-1.5 rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-700 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
                    id="menu-button"
                    aria-expanded="true"
                    aria-haspopup="true"
                    onClick={() => setIsOpen(!isOpen)}
                >
                    {humanize(value) || humanize(label)}
                    <svg
                        className="-mr-1 h-5 w-5 text-gray-400"
                        viewBox="0 0 20 20"
                        fill="currentColor"
                        aria-hidden="true"
                    >
                        <path
                            fillRule="evenodd"
                            d="M5.23 7.21a.75.75 0 011.06.02L10 11.168l3.71-3.938a.75.75 0 111.08 1.04l-4.25 4.5a.75.75 0 01-1.08 0l-4.25-4.5a.75.75 0 01.02-1.06z"
                            clipRule="evenodd"
                        />
                    </svg>
                </button>
            </div>

            <div
                className={`${
                    isOpen ? "" : "hidden"
                }  absolute right-0 z-10 mt-2 w-56 origin-top-right divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none`}
                role="menu"
                aria-orientation="vertical"
                aria-labelledby="menu-button"
            >
                <div className="py-1" role="none">
                    {items?.map((item, idx) => {
                        const optionClass = item.isCaution
                            ? "text-red-400 hover:text-red-600  block px-4 py-2 text-sm hover:bg-gray-100"
                            : "text-gray-700 block px-4 py-2 text-sm hover:bg-gray-100 hover:text-gray-900";
                        return (
                            <a
                                key={idx}
                                href="#"
                                className={optionClass}
                                role="menuitem"
                                id="menu-item-0"
                                onClick={() => {
                                    setIsOpen(false);
                                    if (item?.value !== value) {
                                        item.action && item.action(item?.value);
                                        setValue(item?.value || "");
                                    }
                                }}
                            >
                                {item?.label}
                            </a>
                        );
                    })}
                </div>
            </div>
        </div>
    );
};

export default React.memo(Dropdown);
