import React, { useEffect, useState } from "react";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { useHistory, useParams } from "react-router-dom";
import { getAlbumById } from "../../core/services/album.service";
import { reorder, submitAnswer } from "../../core/services/quiz.service";
import QuestionTypeIcon from "../quiz-studio/QuestionTypeIcon";
import { CheckIcon, XIcon } from "@heroicons/react/solid";

import bubblesGray from "./../../../assets/images/bubbles-gray.png";
import bubblesGreen from "./../../../assets/images/bubbles-green.png";
import bubblesRed from "./../../../assets/images/bubbles-red.png";
import Modal from "react-modal";

type AlbumsParams = {
    id: string;
};
const Quiz = () => {
    const { id } = useParams<AlbumsParams>();

    const history = useHistory();

    const [album, setAlbum] = useState<any>();

    const [questions, setQuestions] = useState([]);

    const [currentIndex, setCurrentIndex] = useState<number>(0);

    const [currentQuestion, setCurrentQuestion] = useState<any>();

    const [currentAnswer, setCurrentAnswer] = useState<any>();

    const [currentBG, setCurrentBG] = useState(bubblesGray);

    const [isSubmitted, setIsSubmitted] = useState(false);

    const [isCorrect, setIsCorrect] = useState(false);

    const [isMobile] = useState(window.innerWidth < 768);

    const [modalIsOpen, setModalIsOpen] = useState(false);

    useEffect(() => {
        getAlbumById(id)
            .then((resp) => {
                setAlbum(resp.data);

                setQuestions(resp.data.relationships.quiz);

                setCurrentQuestion(resp.data.relationships.quiz[currentIndex]);
            })
            .catch((err) => {
                history.push("/");
            });
    }, []);

    const onSubmit = () => {
        submitAnswer(currentQuestion.id, currentAnswer).then((resp) => {
            setIsSubmitted(true);

            setIsCorrect(resp.data.is_correct);

            setCurrentBG(() => {
                return resp.data.is_correct ? bubblesGreen : bubblesRed;
            });

            if (isMobile && resp.data.is_correct) {
                setModalIsOpen(true);

                setTimeout(() => {
                    setModalIsOpen(false);
                }, 3000);
            }
        });
    };

    const onNextQuestion = () => {
        if (currentIndex + 1 === questions.length) {
            history.push(`/albums/${album.id}`);
            return;
        }
        setCurrentIndex((currentState: number) => {
            const tempIndex = currentState + 1;
            setCurrentQuestion(() => {
                return questions[tempIndex];
            });
            return tempIndex;
        });

        setCurrentAnswer(null);

        setCurrentBG(bubblesGray);

        setIsSubmitted(false);

        setIsCorrect(false);
    };

    return (
        <>
            {currentQuestion && (
                <div
                    className="container text-white py-20 px-6 md:px-12 my-6 md:my-12  relative bg-cover bg-center bg-no-repeat h-screen md:h-auto"
                    style={{
                        backgroundImage: `url(${
                            isMobile ? bubblesGray : currentBG
                        })`,
                    }}
                >
                    <div className={`space-y-8`}>
                        <div className="space-y-4">
                            <div className={"text-sm text-gray-400"}>
                                Question {currentIndex + 1}/{questions.length}
                            </div>
                            <div className="text-lg flex gap-2">
                                <QuestionTypeIcon
                                    type={currentQuestion.attributes.type}
                                />
                                <div> {currentQuestion.attributes.title}</div>
                            </div>

                            {isMobile && !isCorrect && isSubmitted ? (
                                <div className="text-red-500">
                                    Your answer is wrong.
                                </div>
                            ) : null}
                        </div>

                        <div className="flex flex-col md:flex-row gap-10 ">
                            <div className="space-y-4 w-full md:w-3/4">
                                <div className={"text-sm text-gray-400"}>
                                    Select right answer
                                </div>

                                <DragDropContext
                                    onDragEnd={(result) => {
                                        if (!result.destination) {
                                            return;
                                        }

                                        const items = reorder(
                                            currentQuestion.relationships
                                                .answers,
                                            result.source.index,
                                            result.destination.index
                                        );

                                        setCurrentQuestion(
                                            (currentState: any) => {
                                                return {
                                                    ...currentState,
                                                    relationships: {
                                                        ...currentState.relationships,
                                                        answers: items,
                                                    },
                                                };
                                            }
                                        );

                                        setCurrentAnswer(
                                            items.map((x) => x.id).join(",")
                                        );
                                    }}
                                >
                                    <Droppable
                                        droppableId="droppable"
                                        isDropDisabled={
                                            currentQuestion.attributes.type !==
                                            "sort"
                                        }
                                    >
                                        {(provided, snapshot) => (
                                            <div
                                                {...provided.droppableProps}
                                                ref={provided.innerRef}
                                            >
                                                {currentQuestion.relationships.answers.map(
                                                    (
                                                        answer: any,
                                                        index: number
                                                    ) => {
                                                        return (
                                                            <Draggable
                                                                key={answer.id}
                                                                draggableId={answer.id.toString()}
                                                                index={index}
                                                            >
                                                                {(
                                                                    provided,
                                                                    snapshot
                                                                ) => (
                                                                    <div
                                                                        ref={
                                                                            provided.innerRef
                                                                        }
                                                                        {...provided.draggableProps}
                                                                    >
                                                                        <div
                                                                            className={`flex gap-6 mb-2 py-2 px-4 rounded ${
                                                                                currentAnswer ===
                                                                                    answer.id &&
                                                                                "bg-gray-400 bg-opacity-30"
                                                                            }`}
                                                                            key={
                                                                                answer.id
                                                                            }
                                                                        >
                                                                            {currentQuestion
                                                                                .attributes
                                                                                .type ===
                                                                            "sort" ? (
                                                                                <div
                                                                                    {...provided.dragHandleProps}
                                                                                >
                                                                                    <QuestionTypeIcon
                                                                                        type={
                                                                                            currentQuestion
                                                                                                .attributes
                                                                                                .type
                                                                                        }
                                                                                    />
                                                                                </div>
                                                                            ) : (
                                                                                <div
                                                                                    className={
                                                                                        "bg-white text-jp-black px-2 rounded uppercase cursor-pointer"
                                                                                    }
                                                                                    onClick={() => {
                                                                                        setCurrentAnswer(
                                                                                            answer.id
                                                                                        );
                                                                                    }}
                                                                                >
                                                                                    {String.fromCharCode(
                                                                                        97 +
                                                                                            index
                                                                                    )}
                                                                                </div>
                                                                            )}
                                                                            <div>
                                                                                {
                                                                                    answer
                                                                                        .attributes
                                                                                        .title
                                                                                }
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                )}
                                                            </Draggable>
                                                        );
                                                    }
                                                )}
                                            </div>
                                        )}
                                    </Droppable>
                                </DragDropContext>
                            </div>

                            <div className="flex-col gap-4 items-center py-10 md:py-0 hidden md:flex">
                                {isSubmitted && (
                                    <>
                                        {isCorrect ? (
                                            <>
                                                <CheckIcon className="w-10" />
                                                <div>
                                                    Your answer is correct
                                                </div>
                                            </>
                                        ) : (
                                            <>
                                                <XIcon className="w-10" />
                                                <div>Your answer is wrong</div>
                                            </>
                                        )}
                                    </>
                                )}
                            </div>
                        </div>

                        <div>
                            {currentAnswer && (
                                <div>
                                    {!isSubmitted ? (
                                        <button
                                            className={
                                                "px-4 py-2 bg-jp-purple-500  opacity-70 hover:opacity-100 w-full md:w-auto"
                                            }
                                            onClick={() => onSubmit()}
                                        >
                                            Submit
                                        </button>
                                    ) : (
                                        <div className="">
                                            {isCorrect ? (
                                                <button
                                                    className="px-4 py-2 bg-jp-purple-500 opacity-70 hover:opacity-100 w-full md:w-auto"
                                                    onClick={() =>
                                                        onNextQuestion()
                                                    }
                                                >
                                                    {currentIndex + 1 ===
                                                    questions.length
                                                        ? "Finish"
                                                        : "Next Question"}
                                                </button>
                                            ) : (
                                                <div className="flex gap-4 flex-col md:flex-row">
                                                    {currentIndex + 1 ===
                                                        questions.length && (
                                                        <button
                                                            className={
                                                                "px-4 py-2 bg-jp-purple-500  opacity-70 hover:opacity-100 "
                                                            }
                                                            onClick={() => {
                                                                window.location.reload();
                                                            }}
                                                        >
                                                            Try Again
                                                        </button>
                                                    )}

                                                    <button
                                                        className="px-4 py-2 border border-jp-purple-500 text-jp-purple-500 opacity-70 hover:opacity-100"
                                                        onClick={() =>
                                                            onNextQuestion()
                                                        }
                                                    >
                                                        {currentIndex + 1 ===
                                                        questions.length
                                                            ? "Finish"
                                                            : "Next Question"}{" "}
                                                    </button>
                                                </div>
                                            )}
                                        </div>
                                    )}
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            )}

            <Modal
                isOpen={modalIsOpen}
                onRequestClose={() => {
                    setModalIsOpen(false);
                }}
                style={{
                    content: {
                        top: "50%",
                        left: "50%",
                        right: "auto",
                        bottom: "auto",
                        marginRight: "-50%",
                        transform: "translate(-50%, -50%)",
                        width: 300,
                        height: 300,
                        borderRadius: 0,
                        backgroundImage: `url(${currentBG})`,
                        borderWidth: 0,
                        backgroundSize: "800px 400px",
                        backgroundPositionX: 300,
                        backgroundPositionY: -30,
                    },
                    overlay: {
                        background: "rgba(0,0,0,0.70)",
                    },
                }}
            >
                <div
                    className="grid place-items-center  text-white  h-full "
                    onClick={() => {
                        setModalIsOpen(false);
                    }}
                >
                    <div className="flex-col flex items-center">
                        {isSubmitted && (
                            <>
                                {isCorrect ? (
                                    <>
                                        <CheckIcon className="w-10" />
                                        <div>Your answer is correct</div>
                                    </>
                                ) : (
                                    <>
                                        <XIcon className="w-10" />
                                        <div>Your answer is wrong</div>
                                    </>
                                )}
                            </>
                        )}
                    </div>
                </div>
            </Modal>
        </>
    );
};

export default Quiz;
