import { Dialog, Transition } from "@headlessui/react";
import { XIcon } from "@heroicons/react/solid";
import { Fragment, useEffect, useState } from "react";
import ResourceFilters from "./ResourceFilters";
import TypeAndCategory from "./TypeAndCategory";

const FiltersSidebar: React.FC<{
    isOpen: boolean;
    close: () => void;
    resourceTypes: any[];
    resourceCategories: any[];
    selectedTypeAndCategory: TypeAndCategory;
    setSelectedTypeAndCategory: React.Dispatch<
        React.SetStateAction<TypeAndCategory>
    >;
}> = (props) => {
    const [open, setOpen] = useState(props.isOpen);

    useEffect(() => {
        setOpen(props.isOpen);
    }, [props.isOpen]);

    useEffect(() => {
        if (!open) {
            props.close();
        }
    }, [open]);

    return (
        <Transition.Root show={open} as={Fragment}>
            <Dialog
                as="div"
                className="fixed inset-0 overflow-hidden z-50"
                onClose={setOpen}
            >
                <div className="absolute inset-0 overflow-hidden">
                    <Transition.Child
                        as={Fragment}
                        enter="ease-in-out duration-500"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="ease-in-out duration-500"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <Dialog.Overlay className="absolute inset-0 bg-gray-900 bg-opacity-75 transition-opacity" />
                    </Transition.Child>

                    <div className="fixed inset-y-0 left-0 w-auto max-w-sm flex">
                        <Transition.Child
                            as={Fragment}
                            enter="transform transition ease-in-out duration-500 sm:duration-700"
                            enterFrom="-translate-x-full"
                            enterTo="translate-x-0"
                            leave="transform transition ease-in-out duration-500 sm:duration-700"
                            leaveFrom="translate-x-0"
                            leaveTo="-translate-x-full"
                        >
                            <div className="h-full flex flex-col p-6 bg-jp-black-800 shadow-xl overflow-y-scroll">
                                <div
                                    className="mt-6 relative flex-1 absolute inset-0 h-full flex flex-col justify-between"
                                    aria-hidden="true"
                                >
                                    <button
                                        type="button"
                                        className="text-white hover:text-gray-500 focus:outline-none absolute right-0"
                                        onClick={() => setOpen(false)}
                                    >
                                        <span className="sr-only">
                                            Close panel
                                        </span>
                                        <XIcon
                                            className="h-6 w-6"
                                            aria-hidden="true"
                                        />
                                    </button>

                                    <ResourceFilters
                                        resourceTypes={props.resourceTypes}
                                        resourceCategories={
                                            props.resourceCategories
                                        }
                                        selectedTypeAndCategory={
                                            props.selectedTypeAndCategory
                                        }
                                        setSelectedTypeAndCategory={
                                            props.setSelectedTypeAndCategory
                                        }
                                    />
                                </div>
                            </div>
                        </Transition.Child>
                    </div>
                </div>
            </Dialog>
        </Transition.Root>
    );
};

export default FiltersSidebar;
