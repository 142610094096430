import React from "react";
import Skeleton from "react-loading-skeleton";

const RowLoader = () => {
    return (
        <div className="flex gap-6">
            <div className="space-y-2 w-1/4">
                <div>
                    <Skeleton height={25} />
                </div>
                <div>
                    <Skeleton height={195} />
                </div>
            </div>
            <div className="space-y-2 w-1/4">
                <div>
                    <Skeleton height={25} />
                </div>
                <div>
                    <Skeleton height={195} />
                </div>
            </div>
            <div className="space-y-2 w-1/4">
                <div>
                    <Skeleton height={25} />
                </div>
                <div>
                    <Skeleton height={195} />
                </div>
            </div>
            <div className="space-y-2 w-1/4">
                <div>
                    <Skeleton height={25} />
                </div>
                <div>
                    <Skeleton height={195} />
                </div>
            </div>
        </div>
    );
};

export default RowLoader;
