import React, { useContext, useEffect, useState } from "react";
import { useRouteMatch } from "react-router-dom";
import FilterSelector from "../../core/component/FilterSelector";
import { AuthContext } from "../../core/context/auth";
import {
    DurationFilter,
    getUserStats,
} from "../../core/services/analytic.service";
import { getUserById } from "../../core/services/auth.service";
import AlbumHistory from "./AlbumHistory";
import Analytic from "./Analytic";
import CallSession from "./CallSession";
import MobileAndWebUsage from "./MobileAndWebUsage";

const UserAnalytics = () => {
    const { authState } = useContext(AuthContext);

    const { params } = useRouteMatch<{ userId: string }>();

    const { userId } = params;

    const [selectedDuration, setSelectedDuration] = useState<DurationFilter>();

    const [analyticsState, setAnalyticsState] = useState<any>();

    const [back] = useState(
        new URLSearchParams(window.location.search).get("back") ?? 0
    );

    const [localUser, setLocalUser] = useState<any>();

    const [canOpenAlbumAnalytics, setCanOpenAlbumAnalytics] = useState(true);

    useEffect(() => {
        if (userId && selectedDuration) {
            setAnalyticsState(null);

            getUserStats(userId, selectedDuration).then((resp) => {
                setAnalyticsState(resp.data);
            });

            getUserById(userId).then((resp) => {
                setLocalUser(resp.data.data);
                setCanOpenAlbumAnalytics(() => {
                    if (authState.user.attributes.is_admin) {
                        return true;
                    }
                    return resp.data.data.relationships.team?.role === "admin";
                });
            });
        }
    }, [userId, selectedDuration]);

    return (
        <div className="container text-white pr-10">
            <div className="flex justify-end mt-6">
                <FilterSelector
                    onChange={(value: DurationFilter) => {
                        setSelectedDuration(value);
                    }}
                />
            </div>
            <div className={`${analyticsState ? "hidden" : "flex"} text-white`}>
                Loading...
            </div>
            <div
                className={`space-y-12 mb-20 ${
                    analyticsState ? "visible" : "invisible"
                }`}
            >
                <Analytic
                    analyticsState={analyticsState}
                    selectedDuration={selectedDuration}
                    back={back}
                    title={
                        localUser?.attributes.name ||
                        localUser?.attributes.email
                    }
                />

                <MobileAndWebUsage analyticsState={analyticsState} />

                <CallSession analyticsState={analyticsState} />

                <AlbumHistory
                    userId={userId}
                    selectedDuration={selectedDuration}
                    teamId={localUser?.relationships?.team?.id}
                    canOpenAlbumAnalytics={canOpenAlbumAnalytics}
                />
            </div>
        </div>
    );
};

export default UserAnalytics;
