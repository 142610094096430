import React, { useEffect, useState } from "react";
import { ArrowLeftIcon, EyeIcon } from "@heroicons/react/outline";
import { Link, useHistory, useRouteMatch } from "react-router-dom";
import {
    DurationFilter,
    getAlbumStats,
    getTeamAlbumStats,
} from "../../core/services/analytic.service";
import videoIcon from "./../../../assets/images/video.svg";
import audioIcon from "./../../../assets/images/audio.svg";
import placeholderImage from "./../../../assets/images/placeholder.jpg";
import teamAlbumPlaceholderImage from "./../../../assets/images/team-album-placeholder.jpg";
import ProgressBar from "../../core/component/ProgressBar";
import FilterSelector from "../../core/component/FilterSelector";
import { uniqueId } from "lodash";

const AlbumAnalytics = () => {
    const { params } = useRouteMatch<{ albumId: string }>();

    const { albumId } = params;

    const [selectedDuration, setSelectedDuration] =
        useState<DurationFilter>();

    const history = useHistory();

    const [albumStats, setAlbumStats] = useState<any>();

    const query = new URLSearchParams(history?.location.search);

    const [teamId] = useState(query.get("teamId"));

    useEffect(() => {
        if (selectedDuration) {
            if (teamId && teamId !== "null") {
                getTeamAlbumStats(albumId, teamId, {
                    filter: selectedDuration,
                }).then((resp) => {
                    setAlbumStats(resp.data);
                });
            } else {
                getAlbumStats(albumId, { filter: selectedDuration }).then(
                    (resp) => {
                        setAlbumStats(resp.data);
                    }
                );
            }
        }
    }, [selectedDuration]);

    return (
        <>
            {albumStats ? (
                <div className="container text-white pr-10 space-y-6 mt-6">
                    <div className="flex justify-between items-center">
                        <div className="flex gap-6">
                            <div
                                className="cursor-pointer "
                                onClick={() => {
                                    history.goBack();
                                }}
                            >
                                <ArrowLeftIcon className="w-6" />
                            </div>
                            <div className="text-xl">Album History Review</div>
                        </div>

                        <div>
                            <FilterSelector
                                onChange={(value: DurationFilter) => {
                                    setSelectedDuration(value);
                                }}
                            />
                        </div>
                    </div>
                    <div className="flex gap-4 items-center">
                        <div className="w-1/4 space-y-2">
                            <div className="">
                                <Link to={`/albums/${albumId}?toHome=0`}>
                                    <div
                                        className={`aspect-w-16 aspect-h-9 rounded overflow-hidden`}
                                    >
                                        <div
                                            style={{
                                                backgroundImage: `url(${
                                                    albumStats.attributes
                                                        .image ||
                                                    (teamId
                                                        ? teamAlbumPlaceholderImage
                                                        : placeholderImage)
                                                }`,
                                                height: 200,
                                            }}
                                            className={`w-full bg-no-repeat bg-contain `}
                                        ></div>
                                    </div>
                                </Link>
                            </div>

                            <div>
                                <div className={"flex items-center gap-2"}>
                                    <div>
                                        <img
                                            src={
                                                albumStats.attributes.type ===
                                                "video"
                                                    ? videoIcon
                                                    : audioIcon
                                            }
                                            alt="Album Type"
                                        />
                                    </div>
                                    <div className={"text-sm truncate"}>
                                        {albumStats.attributes.title}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="flex-1 grid grid-cols-2 grid-rows-2 text-center gap-y-6 items-center">
                            <div className="border-r  border-gray-500 space-y-4 pr-6">
                                <div>Number of unique watchers</div>
                                <div>
                                    <span className="border-b border-gray-500 pb-4 px-4 text-2xl">
                                        {albumStats.attributes.unique_watchers}
                                    </span>
                                </div>
                            </div>
                            <div className="space-y-4">
                                <div>Total time of video views</div>
                                <div>
                                    <span className="border-b border-gray-500 pb-4 px-4 text-2xl">
                                        {
                                            albumStats.attributes
                                                ?.total_playback_minutes
                                        }
                                        m
                                    </span>
                                </div>
                            </div>
                            <div className="border-r  border-gray-500 space-y-4 pt-1 pr-6">
                                <div>People watched the whole album</div>
                                <div>
                                    <span className="text-2xl">
                                        {
                                            albumStats.attributes
                                                ?.users_watched_entire_album
                                        }
                                    </span>
                                </div>
                            </div>
                            <div className=" space-y-4 pt-1">
                                <div>Average watch time</div>
                                <div>
                                    <span className="text-2xl">
                                        {
                                            albumStats.attributes
                                                ?.average_watch_time_percent
                                        }
                                        %
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="border border-gray-500 rounded p-6">
                        <div className="text-xl mb-2">
                            Users who watched the album
                        </div>
                        <div className="grid grid-cols-2 gap-6">
                            {!albumStats.attributes.watchers && (
                                <div className="bg-jp-black-700 p-2 rounded">
                                    No watchers yet.
                                </div>
                            )}
                            {albumStats.attributes.watchers?.map(
                                (watcher: any) => {
                                    return (
                                        <div
                                            className="bg-jp-black-700 p-2 rounded"
                                            key={uniqueId()}
                                        >
                                            <ProgressBar
                                                title={
                                                    (watcher.attributes.name ||
                                                        "") +
                                                    " (" +
                                                    watcher.attributes.email +
                                                    ")"
                                                }
                                                percent={
                                                    watcher.attributes
                                                        .album_completed
                                                        ? 100
                                                        : 0
                                                }
                                            />
                                            <div className="flex justify-between items-center">
                                                <div>
                                                    Duration:{" "}
                                                    {
                                                        watcher.attributes
                                                            .album_minutes_watched
                                                    }
                                                    m
                                                </div>
                                                <div className="flex gap-4 items-center">
                                                    <EyeIcon className="w-4" />
                                                    <div>
                                                        {
                                                            watcher.attributes
                                                                .album_times_watched
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    );
                                }
                            )}
                        </div>
                    </div>
                </div>
            ) : (
                "Loading.."
            )}
        </>
    );
};

export default AlbumAnalytics;
