// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.playlists_headerImage__MnYbP {
  min-height: 120px;
  max-height: 30%;
}
@media (min-width: 640px) {
  .playlists_headerImage__MnYbP {
    min-height: 240px;
  }
}
@media (min-width: 768px) {
  .playlists_headerImage__MnYbP {
    min-height: 360px;
  }
}
@media (min-width: 1024px) {
  .playlists_headerImage__MnYbP {
    min-height: 600px;
  }
}

.playlists_spacer__1c05F {
  min-height: 150px;
  max-height: 30%;
}
@media (max-width: 640px) {
  .playlists_spacer__1c05F {
    min-height: 320px;
  }
}
@media (min-width: 640px) {
  .playlists_spacer__1c05F {
    min-height: 220px;
  }
}
@media (min-width: 768px) {
  .playlists_spacer__1c05F {
    min-height: 320px;
  }
}
@media (min-width: 1024px) {
  .playlists_spacer__1c05F {
    min-height: 500px;
  }
}`, "",{"version":3,"sources":["webpack://./src/app/modules/playlists/playlists.module.scss"],"names":[],"mappings":"AAAA;EACI,iBAAA;EACA,eAAA;AACJ;AAEI;EALJ;IAMQ,iBAAA;EACN;AACF;AAEI;EAVJ;IAWQ,iBAAA;EACN;AACF;AAEI;EAfJ;IAgBQ,iBAAA;EACN;AACF;;AAEA;EACI,iBAAA;EACA,eAAA;AACJ;AACI;EAJJ;IAKQ,iBAAA;EAEN;AACF;AACI;EATJ;IAUQ,iBAAA;EAEN;AACF;AACI;EAdJ;IAeQ,iBAAA;EAEN;AACF;AACI;EAnBJ;IAoBQ,iBAAA;EAEN;AACF","sourcesContent":[".headerImage {\n    min-height: 120px;\n    max-height: 30%;\n\n    // For very small devices and above\n    @media (min-width: 640px) {\n        min-height: 240px;\n    }\n\n    // For medium size devices and above\n    @media (min-width: 768px) {\n        min-height: 360px;\n    }\n\n    // For large size devices and above\n    @media (min-width: 1024px) {\n        min-height: 600px;\n    }\n}\n\n.spacer {\n    min-height: 150px;\n    max-height: 30%;\n\n    @media (max-width: 640px) {\n        min-height: 320px;\n    }\n\n    // For very small devices and above\n    @media (min-width: 640px) {\n        min-height: 220px;\n    }\n\n    // For medium size devices and above\n    @media (min-width: 768px) {\n        min-height: 320px;\n    }\n\n    // For large size devices and above\n    @media (min-width: 1024px) {\n        min-height: 500px;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"headerImage": `playlists_headerImage__MnYbP`,
	"spacer": `playlists_spacer__1c05F`
};
export default ___CSS_LOADER_EXPORT___;
