import httpClient from "../helpers/httpClient";

export const sendInvitation = (teamId: number, payload: any) => {
    return httpClient.post(`/api/v3/teams/${teamId}/invite`, payload);
};

export const getAvailableRoles = () => {
    return httpClient.get("/api/v3/teams/roles");
};

export const getTeamMembers = (teamId: string) => {
    return httpClient.get(`/api/v3/teams/${teamId}/members`);
};

export const getTeamInvites = (teamId: string) => {
    return httpClient.get(`/api/v3/teams/${teamId}/invites`);
};

export const acceptInvitation = (invitationToken: string) => {
    return httpClient.post(`/api/v3/teams/invitation/${invitationToken}`, {});
};

export const updateTeamMember = (
    teamId: string,
    memberId: string,
    role: string
) => {
    return httpClient.post(`/api/v3/teams/${teamId}/members/${memberId}`, {
        role,
    });
};

export const deleteTeamMember = (teamId: string, memberId: string) => {
    return httpClient.delete(`/api/v3/teams/${teamId}/members/${memberId}`);
};

export const createTeam = (name: string) => {
    return httpClient.post(`/api/v3/teams`, { name });
};

export const updateTeamName = (id: any, name: string) => {
    return httpClient.patch(`/api/v3/teams/${id}`, { name });
};
