import httpClient from "../helpers/httpClient";

export const searchAll = (
    text = "",
    size = 10,
    category_id: number | string | null
) => {
    return httpClient.get("/api/v3/search/", {
        params: {
            text,
            size,
            category_id,
        },
    });
};
