export const DarkThemeDropDown = {
    option: (provided: any, state: any) => ({
        ...provided,
        backgroundColor: state.isSelected ? "#292929" : "#212020",
        cursor: "pointer",
    }),
    control: (provided: any) => ({
        ...provided,
        backgroundColor: "#212020",
        color: "#ffffff",
        margin: 0,
        borderColor: "rgb(75, 85, 99)",
    }),
    singleValue: (provided: any) => ({
        ...provided,
        backgroundColor: "#212020",
        color: "#ffffff",
        cursor: "pointer",
    }),
    menu: (provided: any) => ({
        ...provided,
        backgroundColor: "#212020",
        cursor: "pointer",
    }),
};
