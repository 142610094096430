import React, { useEffect, useState } from "react";
import {
    getResourceCategories,
    getResources,
    getResourceType,
} from "../../core/services/resource.service";
import ResourceType from "./ResourceType";
import { uniqBy } from "lodash";
import squares from "../../../assets/images/squares.svg";
import ResourceFilters from "./ResourceFilters";
import FiltersSidebar from "./FiltersSidebar";

const Resources = () => {
    const [sidebarIsOpen, setSidebarIsOpen] = useState(false);

    const [resourceTypes, setResourceTypes] = useState([]);

    const [resourceCategories, setResourceCategories] = useState([]);

    const [resources, setResources] = useState<any>([]);

    const [selectedTypeAndCategory, setSelectedTypeAndCategory] = useState({
        type_id: null,
        category_id: null,
        size: 20,
        page: 1,
    });

    const [paginationLoading, setPaginationLoading] = useState(false);

    useEffect(() => {
        getResourceType()
            .then((resp) => {
                setResourceTypes(resp.data);
            })
            .catch((err) => {
                console.log("🚀 ~ err", err);
            });

        getResourceCategories()
            .then((resp) => {
                setResourceCategories(resp.data);
            })
            .catch((err) => {
                console.log("🚀 ~ err", err);
            });

        const scrollTracking = () => {
            if (
                window.innerHeight + window.scrollY >=
                document.body.scrollHeight
            ) {
                setSelectedTypeAndCategory((currentState) => {
                    return {
                        ...currentState,
                        page: currentState.page + 1,
                    };
                });
            }
        };

        window.addEventListener("scroll", scrollTracking);

        return () => {
            window.removeEventListener("scroll", scrollTracking);
        };
    }, []);

    useEffect(() => {
        setPaginationLoading(true);

        getResources(selectedTypeAndCategory)
            .then((resp) => {
                console.log(
                    "🚀 ~ selectedTypeAndCategory.page",
                    selectedTypeAndCategory.page
                );
                if (selectedTypeAndCategory.page === 1) {
                    window.scrollTo(0, 0);
                    setResources(() => {
                        return [...resp.data];
                    });
                } else {
                    setResources((currentState: any) => {
                        return uniqBy(
                            [...currentState, ...resp.data],
                            (x) => x.id
                        );
                    });
                }
                setPaginationLoading(false);
            })
            .catch((err) => {
                console.log("🚀 ~ err", err);
            });
    }, [selectedTypeAndCategory]);

    return (
        <div className="container text-white p-4 md:p-16">
            <div className="flex md:gap-6 relative">
                {/* The static sidebar of filters to display on larger screens */}
                <div
                    className="invisible md:visible w-0 md:w-1/5 space-y-6 sticky top-10"
                    style={{
                        height: "calc(100vh - 200px)",
                    }}
                >
                    <ResourceFilters
                        resourceTypes={resourceTypes}
                        resourceCategories={resourceCategories}
                        selectedTypeAndCategory={selectedTypeAndCategory}
                        setSelectedTypeAndCategory={setSelectedTypeAndCategory}
                    />
                </div>

                {/* The button to display a slide out list of filters on mobile and small screens */}
                <div
                    className="md:invisible sticky top-4 mr-2"
                    style={{
                        height: "calc(100vh - 200px)",
                    }}
                >
                    <img
                        className="object-fill w-8"
                        src={squares}
                        onClick={() => setSidebarIsOpen(true)}
                        alt=""
                    />
                </div>

                {/* The slide out list of filters for mobile and small screens */}
                <FiltersSidebar
                    isOpen={sidebarIsOpen}
                    close={() => setSidebarIsOpen(false)}
                    resourceTypes={resourceTypes}
                    resourceCategories={resourceCategories}
                    selectedTypeAndCategory={selectedTypeAndCategory}
                    setSelectedTypeAndCategory={setSelectedTypeAndCategory}
                />

                {/* The actual grid of filtered resources */}
                <div className="w-full md:w-4/5">
                    <div className="grid grid-cols-2 lg:grid-cols-3 gap-6 md:gap-8 lg:gap-10">
                        {resources.map((resource: any) => {
                            return (
                                <a
                                    key={resource.id}
                                    className={
                                        "cursor-pointer hover:opacity-80 transition"
                                    }
                                    href={resource.attributes.url}
                                    target="_blank"
                                    rel="noreferrer"
                                    download
                                >
                                    <ResourceType
                                        url={resource.attributes.url}
                                    />
                                    <div className="pt-0 md:pt-2 text-white text-xs md:text-base">
                                        {resource.attributes.title}
                                    </div>
                                </a>
                            );
                        })}
                    </div>
                    {paginationLoading && (
                        <div className={"py-4"}>Loading...</div>
                    )}
                </div>
            </div>
        </div>
    );
};

export default Resources;
