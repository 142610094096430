import React, { useEffect, useState } from "react";
import {
    getLoggedInUser,
    updatePassword,
    updateUser,
} from "../../core/services/auth.service";
import UserEditModal from "./EditModal";
import styles from "./index.module.scss";
import countryCodes from "../../core/helpers/countryCodes";

const UserInfo = () => {
    const countries = countryCodes;

    const [user, setUser] = useState<any>();

    useEffect(() => {
        fetchUser();
    }, []);

    const [openModal, setOpenModal] = useState(false);

    const [modalContent, setModalContent] = useState(<div>Welcome</div>);

    const [submitting, setSubmitting] = useState(false);

    const fetchUser = () => {
        getLoggedInUser().then((resp) => {
            setUser(resp.data.data);
        });
    };

    const onSubmit = (payload: any) => {
        setSubmitting(true);

        updateUser(user.id, payload)
            .then((resp) => {
                fetchUser();
                setOpenModal(false);
            })
            .finally(() => {
                setSubmitting(false);
            });
    };

    const onChangePassword = (payload: any) => {
        setSubmitting(true);

        updatePassword(payload)
            .then((resp) => {
                console.log(
                    "🚀 ~ file: index.tsx ~ line 46 ~ .then ~ resp",
                    resp
                );

                setOpenModal(false);
            })
            .finally(() => {
                setSubmitting(false);
            });
    };

    return (
        <div className="container text-white p-16">
            {user ? (
                <div className={`space-y-6`}>
                    <div className={`space-y-2`}>
                        <div className={styles.formLabel}>Name</div>
                        <div className={styles.formDataDisplay}>
                            {user.attributes.first_name}&nbsp;
                            {user.attributes.last_name}
                        </div>
                        <div
                            className={styles.formEdit}
                            onClick={() => {
                                setModalContent(
                                    <form
                                        onSubmit={(ev) => {
                                            ev.preventDefault();
                                            const formData = ev.target as any;
                                            onSubmit({
                                                first_name:
                                                    formData.first_name.value,
                                                last_name:
                                                    formData.last_name.value,
                                            });
                                        }}
                                    >
                                        <div className={"flex flex-col gap-6"}>
                                            <div>
                                                <label
                                                    className={styles.formLabel}
                                                >
                                                    First Name
                                                </label>

                                                <input
                                                    type="text"
                                                    placeholder="First Name"
                                                    autoFocus
                                                    className={
                                                        styles.formControl
                                                    }
                                                    name="first_name"
                                                    defaultValue={
                                                        user.attributes
                                                            .first_name
                                                    }
                                                />
                                            </div>
                                            <div>
                                                <label
                                                    className={styles.formLabel}
                                                >
                                                    Last Name
                                                </label>
                                                <input
                                                    type="text"
                                                    placeholder="Last Name"
                                                    className={
                                                        styles.formControl
                                                    }
                                                    name="last_name"
                                                    defaultValue={
                                                        user.attributes
                                                            .last_name
                                                    }
                                                />
                                            </div>

                                            <div
                                                className={
                                                    "space-x-6 justify-center flex"
                                                }
                                            >
                                                <button
                                                    type="submit"
                                                    className={
                                                        styles.formEdit +
                                                        ` flex items-center gap-4`
                                                    }
                                                    disabled={submitting}
                                                >
                                                    Save Changes
                                                </button>
                                                <button
                                                    type="button"
                                                    className={"text-red-500"}
                                                    onClick={() => {
                                                        setOpenModal(false);
                                                    }}
                                                >
                                                    Cancel
                                                </button>
                                            </div>
                                        </div>
                                    </form>
                                );

                                setOpenModal(true);
                            }}
                        >
                            change name
                        </div>
                    </div>

                    <div className={`space-y-2`}>
                        <div className={styles.formLabel}>Email</div>
                        <div className={styles.formDataDisplay}>
                            {user.attributes.email}
                        </div>
                        <div
                            className={styles.formEdit}
                            onClick={() => {
                                setModalContent(
                                    <form
                                        onSubmit={(ev) => {
                                            ev.preventDefault();
                                            const formData = ev.target as any;

                                            onSubmit({
                                                email: formData.email.value,
                                            });
                                        }}
                                    >
                                        <div className={"flex flex-col gap-6"}>
                                            <div>
                                                <label
                                                    className={styles.formLabel}
                                                >
                                                    Email
                                                </label>

                                                <input
                                                    required
                                                    type="email"
                                                    placeholder="Email"
                                                    autoFocus
                                                    className={
                                                        styles.formControl
                                                    }
                                                    name="email"
                                                    defaultValue={
                                                        user.attributes.email
                                                    }
                                                />
                                            </div>

                                            <div
                                                className={
                                                    "space-x-6 justify-center flex"
                                                }
                                            >
                                                <button
                                                    type="submit"
                                                    className={
                                                        styles.formEdit +
                                                        ` flex items-center gap-4`
                                                    }
                                                    disabled={submitting}
                                                >
                                                    Save Changes
                                                </button>
                                                <button
                                                    type="button"
                                                    className={"text-red-500"}
                                                    onClick={() => {
                                                        setOpenModal(false);
                                                    }}
                                                >
                                                    Cancel
                                                </button>
                                            </div>
                                        </div>
                                    </form>
                                );

                                setOpenModal(true);
                            }}
                        >
                            change email
                        </div>
                        <div
                            className={styles.formEdit}
                            onClick={() => {
                                setModalContent(
                                    <form
                                        onSubmit={(ev) => {
                                            ev.preventDefault();
                                            const formData = ev.target as any;

                                            onChangePassword({
                                                password:
                                                    formData.password.value,
                                                password_confirmation:
                                                    formData
                                                        .password_confirmation
                                                        .value,
                                            });
                                        }}
                                    >
                                        <div className={"flex flex-col gap-6"}>
                                            <div>
                                                <label
                                                    className={styles.formLabel}
                                                >
                                                    New Password
                                                </label>

                                                <input
                                                    required
                                                    type="password"
                                                    placeholder="New Password"
                                                    autoFocus
                                                    className={
                                                        styles.formControl
                                                    }
                                                    name="password"
                                                />
                                            </div>
                                            <div>
                                                <label
                                                    className={styles.formLabel}
                                                >
                                                    Confirm Password
                                                </label>

                                                <input
                                                    required
                                                    type="password"
                                                    placeholder="Confirm Password"
                                                    autoFocus
                                                    className={
                                                        styles.formControl
                                                    }
                                                    name="password_confirmation"
                                                />
                                            </div>

                                            <div
                                                className={
                                                    "space-x-6 justify-center flex"
                                                }
                                            >
                                                <button
                                                    type="submit"
                                                    className={
                                                        styles.formEdit +
                                                        ` flex items-center gap-4`
                                                    }
                                                    disabled={submitting}
                                                >
                                                    Save Changes
                                                </button>
                                                <button
                                                    type="button"
                                                    className={"text-red-500"}
                                                    onClick={() => {
                                                        setOpenModal(false);
                                                    }}
                                                >
                                                    Cancel
                                                </button>
                                            </div>
                                        </div>
                                    </form>
                                );

                                setOpenModal(true);
                            }}
                        >
                            change password
                        </div>
                    </div>

                    <div className={`space-y-2`}>
                        <div className={styles.formLabel}>Phone</div>
                        <div className={styles.formDataDisplay}>
                            {user.attributes.phone}
                        </div>
                        <div
                            className={styles.formEdit}
                            onClick={() => {
                                setModalContent(
                                    <form
                                        onSubmit={(ev) => {
                                            ev.preventDefault();
                                            const formData = ev.target as any;

                                            onSubmit({
                                                phone: formData.phone.value,
                                            });
                                        }}
                                    >
                                        <div className={"flex flex-col gap-6"}>
                                            <div>
                                                <label
                                                    className={styles.formLabel}
                                                >
                                                    Phone
                                                </label>

                                                <input
                                                    required
                                                    type="tel"
                                                    placeholder="Phone"
                                                    autoFocus
                                                    className={
                                                        styles.formControl
                                                    }
                                                    name="phone"
                                                    defaultValue={
                                                        user.attributes.phone
                                                    }
                                                />
                                            </div>

                                            <div
                                                className={
                                                    "space-x-6 justify-center flex"
                                                }
                                            >
                                                <button
                                                    type="submit"
                                                    className={
                                                        styles.formEdit +
                                                        ` flex items-center gap-4`
                                                    }
                                                    disabled={submitting}
                                                >
                                                    Save Changes
                                                </button>
                                                <button
                                                    type="button"
                                                    className={"text-red-500"}
                                                    onClick={() => {
                                                        setOpenModal(false);
                                                    }}
                                                >
                                                    Cancel
                                                </button>
                                            </div>
                                        </div>
                                    </form>
                                );

                                setOpenModal(true);
                            }}
                        >
                            change phone
                        </div>
                    </div>

                    <div className={`space-y-2`}>
                        <div className={styles.formLabel}>Company</div>
                        <div className={styles.formDataDisplay}>
                            {user.attributes.company}
                        </div>
                        <div
                            className={styles.formEdit}
                            onClick={() => {
                                setModalContent(
                                    <form
                                        onSubmit={(ev) => {
                                            ev.preventDefault();
                                            const formData = ev.target as any;

                                            onSubmit({
                                                company: formData.company.value,
                                            });
                                        }}
                                    >
                                        <div className={"flex flex-col gap-6"}>
                                            <div>
                                                <label
                                                    className={styles.formLabel}
                                                >
                                                    Company
                                                </label>

                                                <input
                                                    required
                                                    type="text"
                                                    placeholder="Company"
                                                    autoFocus
                                                    className={
                                                        styles.formControl
                                                    }
                                                    name="company"
                                                    defaultValue={
                                                        user.attributes.company
                                                    }
                                                />
                                            </div>

                                            <div
                                                className={
                                                    "space-x-6 justify-center flex"
                                                }
                                            >
                                                <button
                                                    type="submit"
                                                    className={
                                                        styles.formEdit +
                                                        ` flex items-center gap-4`
                                                    }
                                                    disabled={submitting}
                                                >
                                                    Save Changes
                                                </button>
                                                <button
                                                    type="button"
                                                    className={"text-red-500"}
                                                    onClick={() => {
                                                        setOpenModal(false);
                                                    }}
                                                >
                                                    Cancel
                                                </button>
                                            </div>
                                        </div>
                                    </form>
                                );

                                setOpenModal(true);
                            }}
                        >
                            change company
                        </div>
                    </div>

                    <div className={`space-y-2`}>
                        <div className={styles.formLabel}>Billing Address</div>
                        <div className={styles.formDataDisplay}>
                            {user.attributes.billing_address_1}
                        </div>
                        <div className={styles.formDataDisplay}>
                            {user.attributes.billing_address_2}
                        </div>
                        <div className={styles.formDataDisplay}>
                            {user.attributes.billing_city}
                        </div>
                        <div className={styles.formDataDisplay}>
                            {user.attributes.billing_state}
                        </div>
                        <div className={styles.formDataDisplay}>
                            {user.attributes.billing_zip}
                        </div>
                        <div className={styles.formDataDisplay}>
                            {user.attributes.billing_country}
                        </div>

                        <div
                            className={styles.formEdit}
                            onClick={() => {
                                setModalContent(
                                    <form
                                        onSubmit={(ev) => {
                                            ev.preventDefault();
                                            const formData = ev.target as any;

                                            onSubmit({
                                                billing_address_1:
                                                    formData.billing_address_1
                                                        .value,
                                                billing_address_2:
                                                    formData.billing_address_2
                                                        .value,
                                                billing_city:
                                                    formData.billing_city.value,
                                                billing_state:
                                                    formData.billing_state
                                                        .value,
                                                billing_country:
                                                    formData.billing_country
                                                        .value,
                                                billing_zip:
                                                    formData.billing_zip.value,
                                            });
                                        }}
                                    >
                                        <div className={"flex flex-col gap-6"}>
                                            <div>
                                                <label
                                                    className={styles.formLabel}
                                                >
                                                    Address 1
                                                </label>

                                                <input
                                                    required
                                                    type="text"
                                                    placeholder="Address 1"
                                                    autoFocus
                                                    className={
                                                        styles.formControl
                                                    }
                                                    name="billing_address_1"
                                                    defaultValue={
                                                        user.attributes
                                                            .billing_address_1
                                                    }
                                                />
                                            </div>

                                            <div>
                                                <label
                                                    className={styles.formLabel}
                                                >
                                                    Address 2
                                                </label>

                                                <input
                                                    type="text"
                                                    placeholder="Address 2"
                                                    className={
                                                        styles.formControl
                                                    }
                                                    name="billing_address_2"
                                                    defaultValue={
                                                        user.attributes
                                                            .billing_address_2
                                                    }
                                                />
                                            </div>

                                            <div>
                                                <label
                                                    className={styles.formLabel}
                                                >
                                                    City
                                                </label>

                                                <input
                                                    required
                                                    type="text"
                                                    placeholder="City"
                                                    className={
                                                        styles.formControl
                                                    }
                                                    name="billing_city"
                                                    defaultValue={
                                                        user.attributes
                                                            .billing_city
                                                    }
                                                />
                                            </div>

                                            <div>
                                                <label
                                                    className={styles.formLabel}
                                                >
                                                    State
                                                </label>

                                                <input
                                                    required
                                                    type="text"
                                                    placeholder="State"
                                                    className={
                                                        styles.formControl
                                                    }
                                                    name="billing_state"
                                                    defaultValue={
                                                        user.attributes
                                                            .billing_state
                                                    }
                                                />
                                            </div>

                                            <div>
                                                <label
                                                    className={styles.formLabel}
                                                >
                                                    Country
                                                </label>

                                                <select
                                                    name="billing_country"
                                                    className={
                                                        styles.formControl
                                                    }
                                                    required
                                                >
                                                    {countries.map(
                                                        (country) => {
                                                            return (
                                                                <option
                                                                    value={
                                                                        country.name
                                                                    }
                                                                    key={
                                                                        country.code
                                                                    }
                                                                    selected={
                                                                        user
                                                                            .attributes
                                                                            .billing_country ===
                                                                         country.name
                                                                    }
                                                                >
                                                                    {
                                                                        country.name
                                                                    }
                                                                </option>
                                                            );
                                                        }
                                                    )}
                                                </select>
                                            </div>

                                            <div>
                                                <label
                                                    className={styles.formLabel}
                                                >
                                                    Postal Code
                                                </label>

                                                <input
                                                    required
                                                    type="text"
                                                    placeholder="Zip"
                                                    className={
                                                        styles.formControl
                                                    }
                                                    name="billing_zip"
                                                    defaultValue={
                                                        user.attributes
                                                            .billing_zip
                                                    }
                                                />
                                            </div>

                                            <div
                                                className={
                                                    "space-x-6 justify-center flex"
                                                }
                                            >
                                                <button
                                                    type="submit"
                                                    className={
                                                        styles.formEdit +
                                                        ` flex items-center gap-4`
                                                    }
                                                    disabled={submitting}
                                                >
                                                    Save Changes
                                                </button>
                                                <button
                                                    type="button"
                                                    className={"text-red-500"}
                                                    onClick={() => {
                                                        setOpenModal(false);
                                                    }}
                                                >
                                                    Cancel
                                                </button>
                                            </div>
                                        </div>
                                    </form>
                                );

                                setOpenModal(true);
                            }}
                        >
                            change billing address
                        </div>
                    </div>

                    <div className={`space-y-2`}>
                        <div className={styles.formLabel}>Shipping Address</div>
                        <div className={styles.formDataDisplay}>
                            {user.attributes.shipping_address_1}
                        </div>
                        <div className={styles.formDataDisplay}>
                            {user.attributes.shipping_address_2}
                        </div>
                        <div className={styles.formDataDisplay}>
                            {user.attributes.shipping_city}
                        </div>
                        <div className={styles.formDataDisplay}>
                            {user.attributes.shipping_state}
                        </div>
                        <div className={styles.formDataDisplay}>
                            {user.attributes.shipping_zip}
                        </div>
                        <div className={styles.formDataDisplay}>
                            {user.attributes.shipping_country}
                        </div>

                        <div
                            className={styles.formEdit}
                            onClick={() => {
                                setModalContent(
                                    <form
                                        onSubmit={(ev) => {
                                            ev.preventDefault();
                                            const formData = ev.target as any;

                                            onSubmit({
                                                shipping_address_1:
                                                    formData.shipping_address_1
                                                        .value,
                                                shipping_address_2:
                                                    formData.shipping_address_2
                                                        .value,
                                                shipping_city:
                                                    formData.shipping_city
                                                        .value,
                                                shipping_state:
                                                    formData.shipping_state
                                                        .value,
                                                shipping_country:
                                                    formData.shipping_country
                                                        .value,
                                                shipping_zip:
                                                    formData.shipping_zip.value,
                                            });
                                        }}
                                    >
                                        <div className={"flex flex-col gap-6"}>
                                            <div>
                                                <label
                                                    className={styles.formLabel}
                                                >
                                                    Address 1
                                                </label>

                                                <input
                                                    required
                                                    type="text"
                                                    placeholder="Address 1"
                                                    autoFocus
                                                    className={
                                                        styles.formControl
                                                    }
                                                    name="shipping_address_1"
                                                    defaultValue={
                                                        user.attributes
                                                            .shipping_address_1
                                                    }
                                                />
                                            </div>

                                            <div>
                                                <label
                                                    className={styles.formLabel}
                                                >
                                                    Address 2
                                                </label>

                                                <input
                                                    type="text"
                                                    placeholder="Address 2"
                                                    className={
                                                        styles.formControl
                                                    }
                                                    name="shipping_address_2"
                                                    defaultValue={
                                                        user.attributes
                                                            .shipping_address_2
                                                    }
                                                />
                                            </div>

                                            <div>
                                                <label
                                                    className={styles.formLabel}
                                                >
                                                    City
                                                </label>

                                                <input
                                                    required
                                                    type="text"
                                                    placeholder="City"
                                                    className={
                                                        styles.formControl
                                                    }
                                                    name="shipping_city"
                                                    defaultValue={
                                                        user.attributes
                                                            .shipping_city
                                                    }
                                                />
                                            </div>

                                            <div>
                                                <label
                                                    className={styles.formLabel}
                                                >
                                                    State
                                                </label>

                                                <input
                                                    required
                                                    type="text"
                                                    placeholder="State"
                                                    className={
                                                        styles.formControl
                                                    }
                                                    name="shipping_state"
                                                    defaultValue={
                                                        user.attributes
                                                            .shipping_state
                                                    }
                                                />
                                            </div>

                                            <div>
                                                <label
                                                    className={styles.formLabel}
                                                >
                                                    Country
                                                </label>

                                                <select
                                                    name="shipping_country"
                                                    className={
                                                        styles.formControl
                                                    }
                                                    required
                                                >
                                                    {countries.map(
                                                        (country) => {
                                                            return (
                                                                <option
                                                                    value={
                                                                        country.name
                                                                    }
                                                                    key={
                                                                        country.code
                                                                    }
                                                                    selected={
                                                                        user
                                                                            .attributes
                                                                            .shipping_country ===
                                                                         country.name
                                                                    }
                                                                >
                                                                    {
                                                                        country.name
                                                                    }
                                                                </option>
                                                            );
                                                        }
                                                    )}
                                                </select>
                                            </div>

                                            <div>
                                                <label
                                                    className={styles.formLabel}
                                                >
                                                    Postal Code
                                                </label>

                                                <input
                                                    required
                                                    type="text"
                                                    placeholder="Zip"
                                                    className={
                                                        styles.formControl
                                                    }
                                                    name="shipping_zip"
                                                    defaultValue={
                                                        user.attributes
                                                            .shipping_zip
                                                    }
                                                />
                                            </div>

                                            <div
                                                className={
                                                    "space-x-6 justify-center flex"
                                                }
                                            >
                                                <button
                                                    type="submit"
                                                    className={
                                                        styles.formEdit +
                                                        ` flex items-center gap-4`
                                                    }
                                                    disabled={submitting}
                                                >
                                                    Save Changes
                                                </button>
                                                <button
                                                    type="button"
                                                    className={"text-red-500"}
                                                    onClick={() => {
                                                        setOpenModal(false);
                                                    }}
                                                >
                                                    Cancel
                                                </button>
                                            </div>
                                        </div>
                                    </form>
                                );

                                setOpenModal(true);
                            }}
                        >
                            change shipping address
                        </div>
                    </div>

                    <UserEditModal
                        openModal={openModal}
                        onClose={() => {
                            setOpenModal(false);
                            setSubmitting(false);
                        }}
                        content={modalContent}
                        submitting={submitting}
                    />
                </div>
            ) : null}
        </div>
    );
};

export default UserInfo;
