import {
    ArrowCircleRightIcon,
    ChevronLeftIcon,
    ChevronRightIcon,
} from "@heroicons/react/solid";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Swiper, SwiperSlide } from "swiper/react";
import { getPlaylists } from "../../core/services/playlist.service";
import styles from "./index.module.scss";

const PlaylistsRow = ({ loading }: any) => {
    const [playlists, setPlaylists] = useState([]);
    useEffect(() => {
        getPlaylists({ size: 10, page: 1 }).then((resp) => {
            setPlaylists(resp.data);
        });
    }, []);
    return (
        <div className="space-y-4 relative group">
            {playlists.length ? (
                <>
                    <div className="flex justify-between -mb-8 lg:-mb-20">
                        <div className="text-gray-500 pl-3 uppercase">
                            Playlist
                        </div>
                        <div className="text-white relative z-10 group-hover:opacity-100 transition opacity-100 md:opacity-0">
                            <Link
                                to="/playlists"
                                className={"flex items-center gap-2"}
                            >
                                <span>View All</span>
                                <ArrowCircleRightIcon className="w-4" />
                            </Link>
                        </div>

                        {/* //controls */}
                        <div className="playlist-button-prev cursor-pointer hover:bg-gray-600 opacity-0 transition group-hover:opacity-100 hover:bg-opacity-40 absolute left-0 top-2/4 -mt-10 z-10">
                            <ChevronLeftIcon className="w-16 text-gray-500 hover:text-white" />
                        </div>

                        <div className="playlist-button-next cursor-pointer hover:bg-gray-600 opacity-0 transition group-hover:opacity-100 hover:bg-opacity-40 absolute right-0 top-2/4 -mt-10 z-10">
                            <ChevronRightIcon className="w-16 text-gray-500 hover:text-white" />
                        </div>
                    </div>
                    <Swiper
                        navigation={{
                            nextEl: ".playlist-button-next",
                            prevEl: ".playlist-button-prev",
                        }}
                        slidesPerView="auto"
                        allowTouchMove={false}
                    >
                        {playlists.map((playlist: any, index: number) => {
                            return (
                                <SwiperSlide
                                    key={playlist.id}
                                    className={`my-8 lg:my-20 mx-3 hover:scale-125 transform-gpu transition rounded shadow-2xl ${
                                        styles.zoomer
                                    } ${styles.itemInRow} ${
                                        index === 0 && "origin-left"
                                    } ${
                                        index === playlists.length - 1 &&
                                        "origin-right"
                                    }`}
                                >
                                    <Link to={"/playlists/" + playlist.id}>
                                        <div
                                            style={{
                                                backgroundImage: `url(${playlist.attributes.image}`,
                                            }}
                                            className="h-full w-full bg-no-repeat bg-cover bg-bottom flex flex-row items-end pb-10 px-2 md:px-5 cursor-pointer rounded"
                                        >
                                            <div className="text-white uppercase tracking-wide w-1/2 text-sm md:text-base">
                                                {playlist.attributes.title}
                                            </div>
                                        </div>
                                    </Link>
                                </SwiperSlide>
                            );
                        })}
                    </Swiper>
                </>
            ) : (
                loading
            )}
        </div>
    );
};

export default PlaylistsRow;
