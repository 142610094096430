import React, { useEffect, useState } from "react";
import Modal from "react-modal";
import Spinner from "../../core/component/Spinner";

const customStyles = {
    content: {
        top: "50%",
        left: "50%",
        right: "auto",
        bottom: "auto",
        marginRight: "-50%",
        transform: "translate(-50%, -50%)",
        width: "40%",
        borderRadius: 0,
        background: "#292929",
        borderColor: "#161616",
    },
    overlay: {
        background: "rgba(0,0,0,0.70)",
    },
};

const UserEditModal = ({
    openModal = false,
    onClose,
    content,
    submitting = false,
}: any) => {
    const [modalIsOpen, setModalIsOpen] = useState(true);

    useEffect(() => {
        setModalIsOpen(openModal);
    }, [openModal]);

    useEffect(() => {
        if (!modalIsOpen) {
            onClose();
        }
    }, [modalIsOpen]);

    return (
        <Modal
            isOpen={modalIsOpen}
            onAfterOpen={() => {
                console.log("Modal Open");
            }}
            onRequestClose={() => {
                setModalIsOpen(false);
            }}
            style={customStyles}
        >
            <div className={`${submitting && "pointer-events-none"} space-y-4`}>
                <div>{content}</div>
                <div className="flex justify-center text-jp-blue">
                    {submitting && <Spinner />}
                </div>
            </div>
        </Modal>
    );
};

export default UserEditModal;
