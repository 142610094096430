import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { ReactComponent as CreditCards } from "./../../../assets/images/cards.svg";
import countryCodes from "../../core/helpers/countryCodes";
import {
    getLoggedInUser,
    updateCardOnFile,
} from "../../core/services/auth.service";
import {
    cancelSubscription,
    getCurrentSubscription,
} from "../../core/services/subscription.service";
import UserEditModal from "../user-info/EditModal";
import styles from "./index.module.scss";
import Invoices from "./Invoices";
import Spinner from "../../core/component/Spinner";
import { Link, useHistory } from "react-router-dom";
import moment from "moment";
import { useIntercom } from "react-use-intercom";
import Swal from "sweetalert2";

declare const recurly: any;

const SubscriptionInfo = () => {
    const history = useHistory();

    const [user, setUser] = useState<any>();

    const [isReady, setIsReady] = useState(false);

    const [currentSubscription, setCurrentSubscription] = useState<any>();

    const [elements] = useState<any>(recurly.Elements());

    const [openModal, setOpenModal] = useState(false);

    const [submitting, setSubmitting] = useState(false);

    const [canCancelSubscription, setCanCancelSubscription] = useState(false);

    const { show } = useIntercom();

    const countries = countryCodes;

    const cardForm = (
        <>
            <div className={"text-xl text-white"}>Billing Information</div>
            {user && (
                <form
                    onSubmit={(ev) => {
                        ev.preventDefault();
                        setSubmitting(true);
                        const form = ev.target as any;
                        recurly.token(
                            elements,
                            form,
                            (err: any, token: any) => {
                                if (err) {
                                    console.log("🚀 ~ err", err);
                                    setSubmitting(false);
                                    toast.error(err.message);
                                } else {
                                    updateCardOnFile({
                                        token_id: token.id,
                                    })
                                        .then((resp) => {
                                            toast.success(
                                                "The card was saved successfully."
                                            );
                                            setSubmitting(false);
                                            setOpenModal(false);
                                        })
                                        .catch((err) => {
                                            setSubmitting(false);
                                        });
                                }
                            }
                        );
                    }}
                >
                    <div className={"flex flex-col gap-6"}>
                        <div className="flex gap-6">
                            <div className={"flex-1"}>
                                <label className={styles.formLabel}>
                                    First Name
                                </label>
                                <input
                                    type="text"
                                    name="first_name"
                                    data-recurly="first_name"
                                    className={styles.formControl}
                                    defaultValue={user.attributes.first_name}
                                />
                            </div>

                            <div className={"flex-1"}>
                                <label className={styles.formLabel}>
                                    Last Name
                                </label>
                                <input
                                    type="text"
                                    name="last_name"
                                    data-recurly="last_name"
                                    className={styles.formControl}
                                    defaultValue={user.attributes.last_name}
                                />
                            </div>
                        </div>

                        <div className="flex gap-6">
                            <div className={"flex-1"}>
                                <label className={styles.formLabel}>
                                    Address
                                </label>

                                <input
                                    type="text"
                                    name="address1"
                                    data-recurly="address1"
                                    className={styles.formControl}
                                    defaultValue={
                                        user.attributes.billing_address_1
                                    }
                                />
                            </div>

                            <div className={"flex-1"}>
                                <label className={styles.formLabel}>City</label>

                                <input
                                    type="text"
                                    name="city"
                                    data-recurly="city"
                                    className={styles.formControl}
                                    defaultValue={user.attributes.billing_city}
                                />
                            </div>
                        </div>
                        <div className="flex gap-6">
                            <div className={"flex-1"}>
                                <label className={styles.formLabel}>
                                    State
                                </label>

                                <input
                                    type="text"
                                    name="state"
                                    data-recurly="state"
                                    className={styles.formControl}
                                    defaultValue={user.attributes.billing_state}
                                />
                            </div>
                            <div className={"flex-1"}>
                                <label className={styles.formLabel}>
                                    Country
                                </label>

                                <select
                                    name="country"
                                    data-recurly="country"
                                    className={styles.formControl}
                                >
                                    {countries.map((country) => {
                                        return (
                                            <option
                                                value={country.code}
                                                key={country.code}
                                                selected={
                                                    user.attributes
                                                        .billing_country
                                                        ? user.attributes
                                                              .billing_country ===
                                                          country.name
                                                        : "Australia" ===
                                                          country.name
                                                }
                                            >
                                                {country.name}
                                            </option>
                                        );
                                    })}
                                </select>
                            </div>
                            <div className={"flex-1"}>
                                <label className={styles.formLabel}>
                                    Postal Code
                                </label>

                                <input
                                    type="text"
                                    name="postal_code"
                                    data-recurly="postal_code"
                                    className={styles.formControl}
                                    defaultValue={user.attributes.billing_zip}
                                />
                            </div>
                        </div>

                        <div className={"flex-1"}>
                            <div className={"text-xl text-white"}>
                                Card Information
                            </div>
                            <div>
                                <div id="recurly-elements"></div>
                            </div>
                        </div>

                        <div className={"space-x-6 justify-center flex"}>
                            <button
                                type="submit"
                                className={
                                    styles.formEdit + ` flex items-center gap-4`
                                }
                                disabled={submitting}
                            >
                                Save Card
                                {submitting && <Spinner />}
                            </button>
                            <button
                                type="button"
                                className={"text-red-500"}
                                onClick={() => {
                                    setOpenModal(false);
                                }}
                            >
                                Cancel
                            </button>
                        </div>
                    </div>
                </form>
            )}
        </>
    );

    const refreshUser = () => {
        getLoggedInUser().then((resp) => {
            const localUser = resp.data.data;
            setUser(localUser);

            if (
                localUser.relationships.team &&
                localUser.relationships.team.attributes.role !== "admin"
            ) {
                history.push("/");
            }
            // console.log(
            //     "🚀 ~ file: index.tsx ~ line 219 ~ getLoggedInUser ~ resp.data.data",
            //     resp.data.data
            // );
        });
    };
    useEffect(() => {
        refreshUser();
    }, []);

    const prepareCardModal = () => {
        getLoggedInUser().then((resp) => {
            setOpenModal(true);
            setUser(resp.data.data);

            setTimeout(() => {
                const cardElement = elements.CardElement();
                cardElement.attach("#recurly-elements");
            }, 500);
        });
    };

    useEffect(() => {
        getCurrentSubscription().then((resp) => {
            setCurrentSubscription(resp.data);

            let localCanCancel = false;

            if (resp.data.period !== "annual") {
                if (
                    moment().diff(moment(resp.data.created_at), "months") >= 12
                ) {
                    localCanCancel = true;
                }
            } else {
                localCanCancel = true;
            }

            setCanCancelSubscription(localCanCancel);

            setIsReady(true);
        });

        recurly.configure(process.env.REACT_APP_RECURLY_KEY);
    }, []);

    const cardPair: any = {
        "American Express": "amex",
        Visa: "visa",
        Discover: "discover",
        MasterCard: "mc",
        JCB: "jcb",
    };

    return (
        <>
            {isReady ? (
                <div className="container text-white p-16 ">
                    {currentSubscription ? (
                        <div className="w-2/4 space-y-6">
                            <div>
                                <div className={styles.formLabel}>
                                    billing details
                                </div>
                                <div className="space-y-4">
                                    {/* <div className={"flex justify-between "}>
                                <div>$84/month</div>
                                <div className={styles.formEdit}>
                                    Switch to annual plan
                                </div>
                            </div> */}
                                    <div className="flex justify-between  items-center">
                                        <div>
                                            <div>Enrolled at</div>
                                        </div>
                                        <div
                                            className={
                                                "text-gray-400 capitalize"
                                            }
                                        >
                                            {moment(
                                                currentSubscription.created_at
                                            ).format("MMM DD, YYYY")}
                                        </div>
                                    </div>
                                    <div className="flex justify-between  items-center">
                                        <div>
                                            <div>Expires at</div>
                                        </div>
                                        <div
                                            className={
                                                "text-gray-400 capitalize"
                                            }
                                        >
                                            {moment(
                                                currentSubscription.expires_at
                                            ).format("MMM DD, YYYY")}
                                        </div>
                                    </div>
                                    <div className="flex justify-between items-center">
                                        <div>
                                            <div>Status</div>
                                        </div>
                                        <div
                                            className={
                                                "text-gray-400 capitalize"
                                            }
                                        >
                                            {currentSubscription.status}
                                        </div>
                                    </div>
                                    <div className="flex justify-between items-center">
                                        <div>
                                            <div>Quantity</div>
                                        </div>
                                        <div
                                            className={
                                                "text-gray-400 capitalize text-right"
                                            }
                                        >
                                            <div>
                                                {currentSubscription.quantity}
                                            </div>
                                            <Link
                                                className={
                                                    styles.formEdit + " text-sm"
                                                }
                                                to="/settings/subscription-info/update-quantity"
                                            >
                                                Update Quantity
                                            </Link>
                                        </div>
                                    </div>
                                    <div className="flex justify-between items-center">
                                        <div>
                                            <div>Subscription Type</div>
                                        </div>
                                        <div
                                            className={
                                                "text-gray-400 capitalize"
                                            }
                                        >
                                            {currentSubscription?.source?.toLowerCase() ===
                                            "recurly"
                                                ? "Credit Card"
                                                : currentSubscription.source}
                                        </div>
                                    </div>
                                    <div className="flex justify-end">
                                        {canCancelSubscription ? (
                                            <a
                                                className={
                                                    "text-jp-blue cursor-pointer"
                                                }
                                                onClick={() => {
                                                    Swal.fire({
                                                        text: "Are you you want to cancel the subscription?",
                                                        showCancelButton: true,
                                                        confirmButtonText:
                                                            "Confirm",
                                                        confirmButtonColor:
                                                            "rgb(239, 68, 68)",
                                                    }).then((result) => {
                                                        if (
                                                            result.isConfirmed
                                                        ) {
                                                            cancelSubscription(
                                                                currentSubscription.id
                                                            ).then(() => {
                                                                toast.success(
                                                                    "Subscription cancelled successfully."
                                                                );
                                                            });
                                                        }
                                                    });
                                                }}
                                            >
                                                Cancel Subscription
                                            </a>
                                        ) : (
                                            <a
                                                onClick={() => {
                                                    show();
                                                }}
                                                id={"launchIntercom"}
                                                className={
                                                    "hover:text-jp-blue cursor-pointer"
                                                }
                                            >
                                                Please contact us to cancel
                                                subscription.
                                            </a>
                                        )}
                                    </div>
                                </div>
                            </div>

                            <div>
                                <div className={styles.formLabel}>
                                    payment methods
                                </div>
                                {user?.attributes.card_last_four ? (
                                    <div className={"flex justify-between"}>
                                        <div
                                            className={
                                                "flex gap-4 items-center"
                                            }
                                        >
                                            <div>
                                                {/* <div>{user.attributes.card_brand}</div> */}
                                                <svg className="w-16 h-12">
                                                    <use
                                                        href={`#${
                                                            cardPair[
                                                                user.attributes
                                                                    .card_brand
                                                            ]
                                                        }-light`}
                                                    ></use>
                                                </svg>
                                            </div>
                                            <div>
                                                <div>
                                                    **** **** ****{" "}
                                                    {
                                                        user.attributes
                                                            .card_last_four
                                                    }
                                                </div>
                                                <div
                                                    className={
                                                        "text-sm text-gray-400"
                                                    }
                                                >
                                                    {
                                                        user.attributes
                                                            .card_exp_month
                                                    }
                                                    /
                                                    {
                                                        user.attributes
                                                            .card_exp_year
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                        <div
                                            className={
                                                styles.formEdit +
                                                " text-right text-sm"
                                            }
                                            onClick={() => {
                                                prepareCardModal();
                                            }}
                                        >
                                            Change payment method
                                        </div>
                                    </div>
                                ) : (
                                    <div className="">
                                        <div
                                            className={styles.formEdit}
                                            onClick={() => {
                                                prepareCardModal();
                                            }}
                                        >
                                            Add a new card
                                        </div>
                                    </div>
                                )}
                            </div>
                            <Invoices />
                        </div>
                    ) : (
                        <div className="flex justify-center py-24">
                            <div className="uppercase text-center bg-gray-900 py-4 px-8 space-y-2">
                                <div>Don't have an account?</div>
                                <a
                                    className="bg-white text-gray-900 py-2 px-28 cursor-pointer block"
                                    href="http://joshphegan.com.au/digital"
                                    target={"_blank"}
                                    rel="noreferrer"
                                >
                                    Sign up for josh phegan
                                </a>
                            </div>
                        </div>
                    )}

                    <UserEditModal
                        openModal={openModal}
                        onClose={() => {
                            setOpenModal(false);
                            refreshUser();
                        }}
                        content={cardForm}
                    />
                    <CreditCards />
                </div>
            ) : null}
        </>
    );
};

export default SubscriptionInfo;
