import React, { FormEvent, useState } from "react";
import { Link } from "react-router-dom";
import { resetPasswordEmailRequest } from "../../core/services/auth.service";
import { toast } from "react-toastify";
import Spinner from "../../core/component/Spinner";

const ResetPassword = () => {
    const [formData, setFormData] = useState({
        email: "",
    });

    const onSubmit = (ev: FormEvent) => {
        ev.preventDefault();
        setSubmitting(true);

        resetPasswordEmailRequest(formData)
            .then((resp) => {
                toast.success(resp.data.message);
                setFormData({ email: "" });
            })
            .finally(() => {
                setSubmitting(false);
            });
    };

    const [submitting, setSubmitting] = useState(false);

    return (
        <>
            <h2 className="text-3xl font-extrabold text-white">
                Forgot password
            </h2>
            <small className="text-white mb-6 block">
                Please type your email below to request a new password.
            </small>
            <form action="#" className="space-y-6" onSubmit={onSubmit}>
                <div className="space-y-1">
                    <label
                        htmlFor="email"
                        className="block text-base font-thin text-gray-300"
                    >
                        Email address
                    </label>
                    <div className="mt-1">
                        <input
                            id="email"
                            name="email"
                            type="email"
                            autoComplete="email"
                            required
                            className="appearance-none block w-full bg-transparent px-3 py-2 border text-white border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-jp-blue focus:border-jp-blue sm:text-sm"
                            onChange={(ev) => {
                                setFormData((currentState) => {
                                    return {
                                        ...currentState,
                                        email: ev.target.value,
                                    };
                                });
                            }}
                        />
                    </div>
                </div>

                <button
                    type="submit"
                    className="w-full flex justify-center py-2  rounded-md text-lg font-light bg-white items-center gap-4"
                    disabled={submitting}
                >
                    Reset Password
                    {submitting && <Spinner />}
                </button>
            </form>
            <div className="mt-8">
                <p className="inline text-gray-300 text-base font-light mr-1">
                    {" "}
                    Go back to{" "}
                </p>
                <Link
                    to={"/auth"}
                    className="text-jp-blue text-base font-light"
                >
                    Sign in
                </Link>
                <p className="inline text-gray-300 text-base font-light mr-1">
                    {" "}
                    form{" "}
                </p>
            </div>
        </>
    );
};

export default ResetPassword;
