import React, { useEffect, useState } from "react";
import { Chart, registerables } from "chart.js";

Chart.register(...registerables);
const MobileAndWebUsage = ({ analyticsState }: any) => {
    const [chartState, setChartState] = useState<any>();

    useEffect(() => {
        const ctx = document.getElementById("barChartCanvas") as any;
        if (ctx && analyticsState) {
            const chartData = analyticsState.activity;

            if (chartState) {
                chartState.destroy();
            }
            const chart = new Chart(ctx, {
                type: "bar",
                data: {
                    labels: Object.keys(
                        chartData?.mobile || chartData?.web || {}
                    ),
                    datasets: [
                        {
                            label: "Mobile",
                            data: Object.values(chartData?.mobile || []),
                            backgroundColor: "rgba(61, 99, 235, 1)",
                        },
                        {
                            label: "Web",
                            data: Object.values(chartData?.web || []),
                            backgroundColor: "rgba(0, 162, 188, 1)",
                        },
                    ],
                },
                options: {
                    scales: {
                        y: {
                            beginAtZero: true,
                            ticks: {
                                precision: 0,
                            },
                        },
                    },
                },
            });

            setChartState(chart);
        }
    }, [analyticsState]);

    return (
        <div className="space-y-6">
            <div className="flex items-center gap-4">
                <div className="text-xl">Mobile & Web Usage</div>
            </div>
            <div className="space-y-12 border rounded p-6 border-gray-600">
                <canvas id="barChartCanvas"></canvas>
            </div>
        </div>
    );
};

export default MobileAndWebUsage;
