import Skeleton from "react-loading-skeleton";
import RowLoader from "./RowLoader";

const ContentLoader = () => {
    return (
        <>
            <div className="flex gap-20 mb-20">
                <div className="space-y-2 w-2/4">
                    <div>
                        <Skeleton height={40} />
                    </div>
                    <div>
                        <Skeleton height={200} />
                    </div>
                    <div className="flex gap-4">
                        <div className="w-2/4">
                            <Skeleton height={32} />
                        </div>
                        <div className="w-2/4">
                            <Skeleton height={32} />
                        </div>
                    </div>
                </div>
                <div className="space-y-2 w-2/4">
                    <div>
                        <Skeleton height={400} />
                    </div>
                </div>
            </div>
            <div>
                <RowLoader />
            </div>
        </>
    );
};

export default ContentLoader;
