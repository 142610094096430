import { ArrowLeftIcon } from "@heroicons/react/solid";
import React from "react";
import { useHistory } from "react-router-dom";

const BackBar = ({ toHome = false }) => {
    const history = useHistory();
    return (
        <div
            className="inline-flex gap-2 mb-4 items-center cursor-pointer text-white"
            onClick={() => (toHome ? history.push("/") : history.goBack())}
        >
            <ArrowLeftIcon className="w-6 -mt-0.5" /> Back
        </div>
    );
};

export default BackBar;
