import httpClient from "../helpers/httpClient";
import { ILoginPayload } from "../interfaces/auth.interface";

export const loginRequest = (payload: ILoginPayload) => {
    return httpClient.post("/oauth/token", {
        ...payload,
        client_id: process.env.REACT_APP_CLIENT_ID,
        client_secret: process.env.REACT_APP_CLIENT_SECRET,
        grant_type: "password",
    });
};

export const registerRequest = (payload: any) => {
    return httpClient.post("/api/v3/users/register", {
        ...payload,
        terms_conditions: 1,
    });
};

export const resetPasswordEmailRequest = (payload: any) => {
    return httpClient.post("/api/v3/password/email", {
        ...payload,
    });
};

export const resetPasswordRequest = (payload: any) => {
    return httpClient.post("/api/v3/password/reset", {
        ...payload,
    });
};

export const getLoggedInUser = () => {
    return httpClient.get("/api/v3/users/current");
};

export const getSettings = () => {
    return httpClient.get("/api/v3/settings");
};

export const updateUser = (id: number, payload: any) => {
    return httpClient.patch(`/api/v3/users/${id}`, payload);
};

export const updatePassword = (payload: any) => {
    return httpClient.patch(`/api/v3/users/password`, payload);
};

export const updateCardOnFile = (payload: any) => {
    return httpClient.post(`/api/v3/users/card-on-file`, payload);
};

export const getInvoices = () => {
    return httpClient.get("/api/v3/users/invoices");
};

export const downloadInvoice = (id: string) => {
    return httpClient.get(`/api/v3/users/invoices/${id}/download`, {
        responseType: "blob",
    });
};

export const getPendingInvitations = () => {
    return httpClient.get(`/api/v3/users/invites`);
};

export const getUserById = (id: string) => {
    return httpClient.get(`/api/v3/users/${id}`);
};
