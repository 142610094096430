import { uniqBy } from "lodash";
import React, { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { AuthContext } from "../../core/context/auth";
import {
    getTeamAlbums,
    getTeamGlobalAlbums,
    getUserRecentAlbums,
    getUserRecentTeamAlbums,
} from "../../core/services/analytic.service";
import AnalyticsAlbumRow from "./AnalyticsAlbumRow";

const AlbumHistory = ({
    userId,
    teamId = null,
    selectedDuration,
    canOpenAlbumAnalytics = true,
}: any) => {
    const [albums, setAlbums] = useState<any>([]);

    const [teamAlbums, setTeamAlbums] = useState<any>([]);

    const { authState } = useContext(AuthContext);

    const [localUserId] = useState(userId || authState.user.id);

    const fetchData = () => {
        if (teamId && authState.teamRole === "admin") {
            getTeamAlbums(teamId, 20, selectedDuration).then((resp) => {
                const localData = resp.data.members || resp.data.albums;
                setTeamAlbums((currentState: any) => {
                    return uniqBy(
                        [...currentState, ...localData],
                        (x: any) => x.id
                    );
                });
            });

            getTeamGlobalAlbums(teamId, 20, selectedDuration).then((resp) => {
                const localData = resp.data.members || resp.data.albums;
                setAlbums((currentState: any) => {
                    return uniqBy(
                        [...currentState, ...localData],
                        (x: any) => x.id
                    );
                });
            });
        } else {
            getUserRecentAlbums(localUserId, 20, selectedDuration).then(
                (resp) => {
                    const localData = resp.data;
                    setAlbums((currentState: any) => {
                        return uniqBy(
                            [...currentState, ...localData],
                            (x: any) => x.id
                        );
                    });
                }
            );

            getUserRecentTeamAlbums(localUserId, 20, selectedDuration).then(
                (resp) => {
                    const localData = resp.data;
                    setTeamAlbums((currentState: any) => {
                        return uniqBy(
                            [...currentState, ...localData],
                            (x: any) => x.id
                        );
                    });
                }
            );
        }
    };

    useEffect(() => {
        if (selectedDuration) {
            fetchData();
        }
    }, [selectedDuration]);

    return (
        <div>
            <div className="text-xl mb-4">Album History</div>

            {!teamAlbums?.length && !albums?.length && (
                <div>No album history available.</div>
            )}

            {albums?.length ? (
                <div>
                    <div className="flex justify-between">
                        <div className="text-gray-400">
                            JOSH PHEGAN DIGITAL ALBUM
                        </div>
                        <div className="flex justify-end relative">
                            <Link
                                to={`/settings/global-albums?teamId=${teamId}&userId=${localUserId}`}
                                className={"absolute z-10"}
                                style={{ width: 60 }}
                            >
                                View All
                            </Link>
                        </div>
                    </div>
                    <AnalyticsAlbumRow
                        albums={albums}
                        teamId={teamId}
                        canOpenAlbumAnalytics={canOpenAlbumAnalytics}
                    />
                </div>
            ) : null}

            {teamAlbums?.length ? (
                <div>
                    <div className="flex justify-between">
                        <div className="text-gray-400">TEAM ALBUM</div>

                        <div className="flex justify-end relative">
                            <Link
                                to={`/settings/team-albums?teamId=${teamId}&userId=${localUserId}`}
                                className={"absolute z-10"}
                                style={{ width: 60 }}
                            >
                                View All
                            </Link>
                        </div>
                    </div>
                    <AnalyticsAlbumRow
                        albums={teamAlbums}
                        teamId={teamId}
                        canOpenAlbumAnalytics={canOpenAlbumAnalytics}
                        teamAlbum={true}
                    />
                </div>
            ) : null}
        </div>
    );
};

export default AlbumHistory;
