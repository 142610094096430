import { Link } from "react-router-dom";
import { Line } from "rc-progress";

import videoIcon from "./../../../assets/images/video.svg";
import audioIcon from "./../../../assets/images/audio.svg";
import placeholderImage from "./../../../assets/images/placeholder.jpg";
import teamAlbumPlaceholderImage from "./../../../assets/images/team-album-placeholder.jpg";

const AlbumItem = ({
    album,
    analytics = false,
    teamAlbum = false,
    teamId = null,
}: any) => {
    const categoryId = album?.relationships?.category?.id;
    const hasMoreThanOneTracks = album?.relationships?.tracks?.length > 1;
    return (
        <>
            {album && (
                <Link
                    to={
                        analytics
                            ? `/settings/album-analytics/${album.id}?teamId=${teamId}`
                            : `/albums/${album.id}${
                                  !hasMoreThanOneTracks && categoryId
                                      ? `?categoryId=${categoryId}`
                                      : ""
                              } `
                    }
                >
                    <div
                        style={{
                            backgroundImage: `url(${
                                album.attributes.image ||
                                (teamAlbum
                                    ? teamAlbumPlaceholderImage
                                    : placeholderImage)
                            }`,
                        }}
                        className={` h-full w-full bg-no-repeat  bg-cover bg-center flex flex-row  items-center px-10 cursor-pointer  rounded`}
                    ></div>

                    <Line
                        percent={album.attributes.progress || 0}
                        strokeColor="#1691D0"
                    />

                    <div className={`text-white   p-2 h-9 overflow-hidden`}>
                        <div className={"flex items-center gap-2"}>
                            <div>
                                <img
                                    src={
                                        album.attributes.type === "video"
                                            ? videoIcon
                                            : audioIcon
                                    }
                                    alt="Album Type"
                                />
                            </div>
                            <div className={"text-sm truncate"}>
                                {album.attributes.title}
                            </div>
                        </div>
                    </div>
                </Link>
            )}
        </>
    );
};

export default AlbumItem;
