// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.auth_background__HEIYJ {
  background-image: url(https://joshphegan.com.au/img/membership_home-min.jpg);
  background-repeat: no-repeat;
  background-position-x: 80%;
  background-size: cover;
}`, "",{"version":3,"sources":["webpack://./src/app/modules/auth/index.module.scss"],"names":[],"mappings":"AAAA;EACI,4EAAA;EACA,4BAAA;EACA,0BAAA;EACA,sBAAA;AACJ","sourcesContent":[".background {\n    background-image: url(https://joshphegan.com.au/img/membership_home-min.jpg);\n    background-repeat: no-repeat;\n    background-position-x: 80%;\n    background-size: cover;\n\n    // For medium size devices and above\n    @media (min-width: 768px) {\n        // background-image: none;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"background": `auth_background__HEIYJ`
};
export default ___CSS_LOADER_EXPORT___;
